

































































import router from "@/router";
import { Iappointment, IDay, IWhatsup } from "@/Services/Interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";



@Component

export default class Day extends Vue {
  
  day: string;
  dayinit: string;
  ansicht: string;
  vorzurück: any;
  ansichttoggle: any;
  direction: number;
  
  

  constructor() {
    super();
    this.name = "days";
  
    this.dayinit = "";
    this.ansicht = "0";
    this.vorzurück = 2;
    this.ansichttoggle = 1;
    this.direction = 0;
    this.day = new Date().toString();
    
  }

  name: string;
  

  getgifappswidth(wh: Iappointment): number {
    if (!wh.gif) return 0;
    return wh.gif.toLowerCase().endsWith("png") ||
      wh.gif.toLowerCase().endsWith("gif")
      ? 15
      : 75;
  }

  getgifwidth(wh: IWhatsup): number {
    if (!wh.Gif) return 0;
    return wh.Gif.toLowerCase().endsWith("png") ||
      wh.Gif.toLowerCase().endsWith("gif")
      ? 15
      : 75;
  }
  newTermin(): void {
    router.push("/dyn/0/create/Termine");
  }

  goto(day: string, ansicht: string, direction: number): void {
    router.push("/days/" + this.dayinit + "/" + ansicht + "/" + direction);
  }
  go(step: number): void {
    this.direction = this.direction + step;
    this.goto(this.day, this.ansichttoggle, this.direction);
  }

  edit(e: number): void {
    router.push("/dyn/" + e + "/update/termine");
  }

 getWhatsupClass(item : IWhatsup )
  {
    var thisclass = "   ";
    if (item.crud == "update") return "alert alert-info";
    return thisclass;
  }

  getAppointmentClass(item : Iappointment )
  {
    var thisclass = "   ";
    if (item.ergebnis == "abgesagt") return "alert alert-warning";
    if (item.ergebnis == "wunderbar") return "alert alert-primary";
    if (item.crud == "update") return "alert alert-info";
    return thisclass;
  }

  getClass(item: IDay): string {
    var thisclass = " rounded small  ";
    if (item.istoday) return thisclass + " alert alert-success";
    if (item.holiday) return thisclass + " holiday ";
    if (item.weekend) return thisclass + " weekend ";

    return thisclass;
  }

  created(): void {
    
  }

  mounted() {
    
  }

 @Prop()
  source: IDay|undefined;

  data() {
    return {
      day: this.dayinit,    
      direction: 0,      
    };
  }

  
}
