

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IStrDetailValue } from "@/Services/Interfaces";

import { globalUpdateDetailService } from "@/Services/GlobalService";

@Component
export default class DeleteButton extends Vue {
  public jetzloeschen = false;
  thismodel: IStrDetailValue = <IStrDetailValue>{};
  funct: string = "";
  thiscanDelete = true;
  constructor() {
    super();

  }

  @Prop()
  model: IStrDetailValue | undefined ;

  @Prop()
  canDelete: boolean|undefined ;

  @Prop()
  functionName : string|undefined;

  cancel(): void {
    this.jetzloeschen = false;
  }

  data() {
    return {
      thismodel: this.model,
      thiscanDelete: this.canDelete,
      funct: this.functionName,
    };
  }


  public get loeschen(): boolean {
    return this.jetzloeschen;
  }

  public set loeschen(v: boolean) {
    this.jetzloeschen = v;
  }

  deleteitem(): void {

    if (this.loeschen && this.loeschen === true) {

      if (this.model != undefined) {
        var thismodel = this.model;
        thismodel.crud = this.funct;
        globalUpdateDetailService.sendMessage(thismodel);
      }
    }

    this.jetzloeschen = !this.jetzloeschen;
  }

  created() {}
}
