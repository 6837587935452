import { Subject, Observable } from 'rxjs';
import {
  IInfo, IWhatsup, IUser, IStrDetailValue, IGrid, IAppControl, Iappointment, IKurs, IFullText,
  IMedia, IMediaConvert, ITopNew, IKursPrediction, IRelation
} from './Interfaces';


export default class GlobalService<T> {
    private subject: Subject<T>;

    constructor() {
      this.subject = new Subject<T>();
      this.name = 'globaleventservice';
    }

    public name: string;


    public sendMessage(mess : T) {
      this.subject.next(mess);
    }

    public getMessage() : Observable<T> {
      return this.subject.asObservable();
    }
}

export const globalInfoService = new GlobalService<IInfo>();
export const globalWhatsUpService = new GlobalService<IWhatsup[]>();
export const globalKurseUpService = new GlobalService<IKurs[]>();
export const globalKursePredictionService = new GlobalService<IKursPrediction[]>();
export const globalUserService = new GlobalService<IUser>();
export const globalUpdateDetailService = new GlobalService<IStrDetailValue>();
export const globalInfoActionService = new GlobalService<IInfo>();
export const globalReceiveDetailsService = new GlobalService<IStrDetailValue>();
export const globalTopNewsReceiveService = new GlobalService<ITopNew>();

export const globalappcontrolService = new GlobalService<IAppControl>();
export const globalAppointmentService = new GlobalService<Iappointment[]>();

export const globalLastSrchService = new GlobalService<IFullText>();
export const globalUpdateMediaService = new GlobalService<IMediaConvert>();
export const globalInnerLinkService = new GlobalService<IRelation[]>();
