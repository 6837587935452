
import {
  openDB, deleteDB, wrap, unwrap,
} from 'idb';

class indexdbService {
    version = 1;

    name = 'stefsdb';

   
    public deleteDB() {
      return indexedDB.deleteDatabase(this.name);
    }

    public db() {
      if (!('indexedDB' in window)) {
        throw new Error('Browser does not support IndexedDB');
      }

      const db0 = openDB(this.name, this.version, {
        upgrade(o, n, t) {
          const store0 = o.createObjectStore('Login', {
            keyPath: 'base_id',
            autoIncrement: false,
          });

          const store2 = o.createObjectStore('Cache', {
            keyPath: 'base_id',
            autoIncrement: false,
          });

          const store3 = o.createObjectStore('Kennung', {
            keyPath: 'base_id',
            autoIncrement: false,
          });
        },
      });

      return db0;
    }
}


export const indedbService = new indexdbService();

/*

const dbPromise = _ => {
  if (!('indexedDB' in window)) {
    throw new Error('Browser does not support IndexedDB')
  }

  return openDB('VueTodoDB', 1, upgradeDb => {
    if (!upgradeDb.objectStoreNames.contains('todos')) {
      upgradeDb.createObjectStore('todos')
    }

    if (!upgradeDb.objectStoreNames.contains('completed')) {
      upgradeDb.createObjectStore('completed')
    }
  })
}

const checkStorage = async storeName => {
  try {
    const db = await dbPromise(null)
    const tx = db.transaction(storeName, 'readonly')
    const store = tx.objectStore(storeName)

    return store.get(storeName)
  } catch (error) {
    return error
  }
}

const saveToStorage = async (storeName, tasks) => {
  try {
    const db = await dbPromise()
    const tx = db.transaction(storeName, 'readwrite')
    const store = tx.objectStore(storeName)

    store.put(tasks, storeName)

    return tx.complete
  } catch (error) {
    return error
  }
}

export default {
  checkStorage,
  saveToStorage
}
*/
