
import './installcompapi.js'
import Vue from "vue";
import App from './App.vue';

import './registerServiceWorker';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueRx from 'vue-rx';
import Vuex, { Store } from 'vuex';
import WebRTC from 'vue-webrtc';

import VueRouter from 'vue-router';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
import VueRecord from '@codekraft-studio/vue-record'


Vue.config.productionTip = false;

Vue.use(VueRecord);
Vue.use(BootstrapVue);
Vue.use(VueRx);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(WebRTC);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
