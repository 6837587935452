


















































import { IPicture, IInfo, IAppControl } from "../Services/Interfaces";
import {
  globalInfoService,
  globalappcontrolService
} from "../Services/GlobalService";
import { dataService } from "../Services/DataServices";
import store from "@/store";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default   defineComponent({
  name: "Gallery",
  setup: (props, context) => {
  const route = context.root.$route;
  var pictures = ref( new Array<IPicture>());
   
    onMounted(() => {
      if (route.params && route.params.base_id) {
      var baseid = Number(route.params.base_id);

      var url = "Service/GetGalleryData/" + baseid;
      dataService.InitbyToken(store.getters.user.token);
      dataService.get<IPicture[]>(url).then(
        g => {
          pictures.value = g;          
          globalappcontrolService.sendMessage(<IAppControl>{
            searchmenuenabled: false
          });
        },
        e => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Getting details for pictures",
            error: e
          });
        }
      );
    }
  })
    return {
      pictures
    };
  }
})
/*

import { Component, Prop, Vue } from "vue-property-decorator";
import { IPicture, IInfo, IAppControl } from "../Services/Interfaces";

import {
  globalInfoService,
  globalappcontrolService
} from "../Services/GlobalService";
import { dataService } from "../Services/DataServices";
import store from "@/store";

@Component
export default class Gallery extends Vue {
  constructor() {
    super();

    this.pictures = new Array<IPicture>();
  }

  pictures: IPicture[];

  data() {
    return {
      pictures: []
    };
  }

  mounted() {
    if (this.$route.params && this.$route.params.base_id) {
      var baseid = Number(this.$route.params.base_id);

      var url = "Service/GetGalleryData/" + baseid;
      dataService.InitbyToken(store.getters.user.token);
      dataService.get<IPicture[]>(url).then(
        g => {
          this.pictures = g;
          globalappcontrolService.sendMessage(<IAppControl>{
            searchmenuenabled: false
          });
        },
        e => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Getting details for pictures",
            error: e
          });
        }
      );
    }
  }
}

*/
