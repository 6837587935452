























































































































































import LoginViewModel, { loginViewModel } from "../Services/LoginViewModel";

import store from "../store";
import { dataService } from "../Services/DataServices";
import {
  IBaseObjectResult,
  IUser,
  IStrDetailValue,
  IFullText,
  IFullTextBase,
  IWhatsup,
  IInfo,
  IRelation,
} from "../Services/Interfaces";
import {
  globalInfoService,
  globalInnerLinkService,
  globalUserService,
  globalappcontrolService,
  globalLastSrchService,
  globalAppointmentService,
  globalWhatsUpService,
} from "../Services/GlobalService";
import router from "../router";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { InfoType, SearchType } from "@/Services/enums";

@Component
export default class Menue extends Vue {
  picture: string;
  typen: string[];
  isLoading: boolean;
  srchlist: IFullText[];
  searchInput: string | undefined;
  timeoutId: any;
  routerList: IFullText[] = new Array<IFullText>();

  constructor() {
    super();
    this.badgeInfo = 0;
    this._logo = "../assets/favicon.png";
    this._pic = "";
    this.user = <IUser>{};
    this.picture = "";
    this.typen = new Array<string>();
    this.isLoading = false;

    this.srchlist = new Array<IFullText>();
    this.timeoutId = null;

    globalAppointmentService.getMessage().subscribe((w) => {
      this.badgeInfo += w.filter((t) => t.crud == "create").length;
    });

    globalWhatsUpService.getMessage().subscribe((wh) => {
      this.badgeInfo = wh.filter((w) =>
        !w.crud || w.crud == "create" ? 1 : 0
      ).length;
    });
  }

  badgeInfo: number;
  selectedtype: string | undefined;
  user: IUser;
  name: "Menue" | undefined;
  msg: String | undefined;
  srchtext: String | undefined;
  typename: String | undefined;
  showSearchMenue = true;

  selected(obj: any) {
    if (
      obj &&
      obj.search &&
      (obj.searchtype == SearchType.FullText ||
        obj.searchtype == SearchType.LastSearch ||
        obj.searchtype == SearchType.NachTypname ||
        obj.searchtype == SearchType.InnerLink ||
        obj.searchtype == SearchType.Router ||
        obj.searchtype == SearchType.OuterLink)
    ) {
      var str = obj.search ? obj.search : obj;

      this.search(str);
    }
  }

  link(link: string): void {
    router.push(link);
  }

  gotoIch(): void {
    router.push("/dyn/" + this.user.base_id + "/update/Adresse");
  }

  loadInnerlinks(): void {
    if (this.user == undefined) return;

    if (
      this.srchlist.filter((t) => t.searchtype == SearchType.InnerLink).length >
      0
    )
      return;
    var url = "Service/GetAutoCompleteValues";
    dataService.InitbyToken(this.user.token);

    var p1 = <any>{
      filter: "%",
      typname: "",
      valuetyp: "innerlink",
      fieldname: "",
    };

    dataService
      .post<IStrDetailValue, IFullTextBase[]>(url, p1)
      .then((d) => {
        for (let index = d.length - 1; index >= 0; index--) {
          let g = d[index];
          this.srchlist.unshift(<IFullText>{
            search: g.search,
            keyword: g.keyword,
            typnames: g.typnames,
            searchtype: SearchType.InnerLink,
            base_id: g.base_id,
            gif: this.getHistoryIcon(),
            description: "Kürzlich aufgerufen",
          })
        }
      })
      .catch((err) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Fehler bei Ermittlung der kürzlich aufgerufenen ",
          error: err,
        });
      })
      .finally(() => (this.isLoading = false));
  }

  loadOuterlinks(): void {
    if (this.user == undefined) return;
    if (this.user.token == undefined) return;

    var url = "Service/GetAutoCompleteValues";
    dataService.InitbyToken(this.user.token);
    var p1 = <any>{
      filter: "%",
      typname: "",
      valuetyp: "outerlink",
      fieldname: "",
    };

    dataService
      .post<IStrDetailValue, IFullTextBase[]>(url, p1)
      .then((d) => {
        this.srchlist = this.srchlist.filter(
          (t) => t.searchtype != SearchType.OuterLink
        );
        d.forEach((g) => {
          this.srchlist.unshift(<IFullText>{
            search: g.search,
            keyword: "",
            typnames: [],
            base_id: -1,
            gif: this.getHistoryIcon(),
            searchtype: SearchType.OuterLink,
            description: "Kürzlich aufgerufen",
          });
        });
      })
      .catch((err) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Fehler bei Ermittlung der kürzlich aufgerufenen ",
          error: err,
        });
      })
      .finally(() => (this.isLoading = false));
  }

  getHistoryIcon(): any {
    return require("../assets/history.png");
  }

  getRouterIcon(): any {
    return require("../assets/router.png");
  }
  getPantherIcon(): any {
    return require("../assets/panther.png");
  }
  getSearchIcon(): any {
    return require("../assets/search.png");
  }

  getRouterLinks(): Array<IFullText> {
    const list = router.stefsRoutes();

    var routerlist = new Array<IFullText>();
    list.forEach((route) => {
      if (route.redirect != undefined) {
        routerlist.push(<IFullText>{
          search: route.name,
          keyword: route.path,
          searchtype: SearchType.Router,
          description: "Wechseln zu..",
          gif: this.getRouterIcon(),
        });
      }
    });

    return routerlist;
  }

  fillinnerLinks(d: IRelation[]): void {
    var wasemptyy = !this.srchlist || this.srchlist.filter(f=> f.searchtype == SearchType.InnerLink ).length == 0;
    d.forEach((g) => {
      let fulltext = <IFullText>{
        search: g.name,
        keyword: g.name,
        typnames: [g.typname],
        base_id: g.baseid,
        gif: this.getHistoryIcon(),
        searchtype: SearchType.InnerLink,
        description: "Kürzlich aufgerufen",
      };
      let idx = this.srchlist.findIndex(
        (t) => t.searchtype == SearchType.InnerLink && t.base_id == g.baseid
      );
      if (idx > -1) {
        this.srchlist[idx] = fulltext;
      } else {
        debugger;
        if (wasemptyy) {
          this.srchlist.push(fulltext);
        } else {
          this.srchlist.unshift(fulltext);
        }
      }
    });
  }

  created(): void {
    this.routerList = this.getRouterLinks();
    this.srchlist = this.srchlist.filter(
      (t) => t.searchtype != SearchType.Router
    );
    this.routerList.forEach((r) => {
      this.srchlist.push(r);
    });

    globalInnerLinkService.getMessage().subscribe((d) => {
      this.fillinnerLinks(d);
    });

    globalappcontrolService.getMessage().subscribe((g) => {
      if (g.searchmenuenabled != undefined) {
        this.showSearchMenue = g.searchmenuenabled;
      }
      if (this.showSearchMenue) {
        this.loadOuterlinks();
      }
    });

    globalLastSrchService.getMessage().subscribe((sr) => {
      if (
        this.srchlist.filter(
          (t) =>
            t.searchtype == SearchType.LastSearch &&
            t.search != undefined &&
            t.search == sr.search
        ).length == 0
      ) {
        this.srchlist.unshift(sr);
        var lastsrch = this.srchlist.filter(
          (t) =>
            t.searchtype == SearchType.LastSearch &&
            t.searchtype == SearchType.LastSearch
        );
        if (lastsrch.length > 5) {
          var last = lastsrch.slice(4);
          for (let index = 0; index < last.length; index++) {
            this.srchlist.splice(index, 1);
          }
        }
      }
    });

    globalUserService.getMessage().subscribe((user) => {
      dataService.InitbyToken(user.token);
      dataService
        .get<IUser>(`Service/GetUserInfos`)
        .then((us) => {
          loginViewModel.initSignal(user.token);
          loginViewModel.createNaventries(user);
          this.loadInnerlinks();
          this.loadOuterlinks();
        })
        .catch((e) => {
          router.push("/login");
          globalInfoService.sendMessage(<IInfo>{
            msg: "Getting Userdata fails",
            error: e,
          });
        });

      this.user = user;
      var url = "Service/getTypen";

      var p = dataService
        .get<string[]>(url)
        .then((g) => {
          this.typen = g;
          this.srchlist = this.srchlist.filter(
            (t) => t.searchtype != SearchType.NachTypname
          );
          this.typen.map((g) => {
            this.srchlist.push(<IFullText>{
              search: g,
              typnames: <string[]>[g],
              searchtype: SearchType.NachTypname,
              description: "Hier suchen ..",
              gif: this.getPantherIcon(),
            });
          });
        })
        .catch((e) => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Load Typen fails...",
            error: e,
          });
        });

      var url = "Service/GetPicture";
      dataService.InitbyToken(user.token);
      var promise = dataService
        .get<IBaseObjectResult>(url)
        .then((p) => {
          if (p.result) {
            this.picture = p.result;
          }
        })
        .catch((r) => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Bild gesucht und nicht gefunden... ",
            error: r,
          });
        });
    });
  }

  logout(event: any) {
    loginViewModel.logOut();
  }

  @Watch("searchInput")
  onPropertyChanged(value: string) {
    try {
      if (!value) return;

      if (value.length < 3) {
        return;
      }
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout((l: any) => {
        this.filter(value);
      }, 700);
    } catch (e) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Fehler bei der Vorauswahl ",
        error: e,
      });
    }
  }

  mounted(): void {}

  /**Filter ausführen bei search änderung */
  filter(srch: string) {
    try {
      if (this.isLoading) return;

      var type = this.srchlist.filter(
        (t) =>
          t.typnames &&
          t.typnames.filter((t) => t == srch).length > 0 &&
          t.searchtype == SearchType.NachTypname
      );

      if (type.length > 0) {
        this.isLoading = false;
        return;
      }

      var firstfd = this.srchlist.filter(
        (t) =>
          t.search != undefined &&
          t.search.startsWith(srch) &&
          t.searchtype == SearchType.FullText
      );
      if (srch.length > 3 && firstfd.length > 0) {
        this.isLoading = false;
      }

      this.isLoading = true;

      if (this.user == undefined || this.user.token == undefined) {
        this.isLoading = false;
        globalInfoService.sendMessage(<IInfo>{
          msg: "Eingeschränktes Suchen.. User noch unbekannt. ",
          type: InfoType.info,
        });
        this.isLoading = false;
        return;
      }

      dataService.InitbyToken(this.user.token);

      var url = "Service/GetAutoCompleteValues";
      dataService.InitbyToken(this.user.token);
      var p1 = <any>{
        filter: srch.toLowerCase(),
        typname: "",
        valuetyp: "unbekannt",
        fieldname: "",
      };

      dataService
        .post<IStrDetailValue, IFullTextBase[]>(url, p1)
        .then((d) => {
          if (d.length > 0) {
            this.srchlist = this.srchlist.filter(
              (t) => t.searchtype != SearchType.FullText
            );
          }
          d.forEach((g) => {
            this.srchlist.push(<IFullText>{
              search: g.search,
              keyword: g.keyword,
              typnames: g.typnames,
              gif: this.getSearchIcon(),
              searchtype: SearchType.FullText,
              description: "Volltext ",
            });
            this.isLoading = false;
          });
        })
        .catch((err) => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Fehler bei der Vorauswahl ",
            error: err,
          });
        })
        .finally(() => (this.isLoading = false));
    } catch (ee) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Fehler bei der Vorauswahl ",
        error: ee,
      });
    }
  }

  private sort(list: IFullText[]): IFullText[] {
    return list.sort((a, b) => (a.keyword > b.keyword ? 1 : -1));
  }

  // https://www.npmjs.com/package/vue-radial-menu
  // @Watch("search")
  public search(str: string | any): void {
    try {
      if (str.target && str.target.value) {
        str = str.target.value;
      }

      this.isLoading = true;
      var searchItem = this.srchlist.filter(
        (t) => t.search != undefined && t.search == str
      )[0];
      if (
        searchItem != undefined &&
        searchItem.searchtype == SearchType.Router
      ) {
        router.push(searchItem.keyword);
      } else if (
        searchItem != undefined &&
        searchItem.searchtype == SearchType.OuterLink
      ) {
        window.open(searchItem.search, searchItem.description, "noreferrer");
      } else if (
        searchItem != undefined &&
        searchItem.searchtype == SearchType.NachTypname
      ) {
        router.push(
          "/srch/*/" +
            searchItem.typnames.join(";") +
            "/" +
            searchItem.searchtype
        );
      } else if (
        searchItem != undefined &&
        (searchItem.searchtype == SearchType.FullText ||
          searchItem.searchtype == SearchType.LastSearch)
      ) {
        router.push(
          "/srch/" + searchItem.keyword + "/Adresse/" + searchItem.searchtype
        );
      } else if (
        searchItem != undefined &&
        searchItem.searchtype != SearchType.NachTypname
      ) {
        if (searchItem.base_id && searchItem.base_id > 0) {
          router.push(
            "/dyn/" +
              searchItem.base_id +
              "/update/" +
              (searchItem.typnames ? searchItem.typnames[0] : "Adresse")
          );
        } else {
          router.push("/srch/" + str + "/all/0");
        }
      } else if (searchItem == undefined) {
        var srch = this.searchInput;
        var typ =
          store.state.favoritetypen.length > 0
            ? store.state.favoritetypen
            : "Adresse";
        router.push("/srch/" + srch + "/" + typ + "/all");
      } else {
        var srch = this.searchInput;
        var typ =
          store.state.favoritetypen.length > 0
            ? store.state.favoritetypen
            : "Adresse";
        router.push("/srch/" + srch + "/" + typ + "/" + searchItem.searchtype);
      }
      store.dispatch("srchtext", srch);
      store.dispatch("srchlist", this.srchlist);
      this.isLoading = false;
      this.badgeInfo = 0;
    } catch (e) {
      this.isLoading = false;
      globalInfoService.sendMessage(<IInfo>{
        msg: " Starten der Suche ging schief...",
        error: e,
      });
    }
  }

  days(): void {
    var d = new Date();
    router.push(
      "/days/" +
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        "/1/0"
    );
  }

  routeTo(str: string): void {
    router.push(str);
  }
  goto(id: number): void {
    router.push("/dyn/" + id + "/update");
  }

  GetPicture(): any {
    return require(this._logo);
  }

  _logo: string;
  _pic: string;

  data() {
    return {
      badgeInfo: 0,
      isLoading: Boolean,
      timeoutId: null,
      collapseOnScroll: true,
      logo: this._logo,
      viewmodel: loginViewModel,
      srchtext: store.getters.srchtxt,
      typename: "",
      searchInput: "",
      srchlist: store.getters.srchlist,
      user: store.getters.user,
      debounce: null,
      picture: this._pic,
      typen: [],
    };
  }
}
