
import Vue from 'vue';
import Router, { RouterOptions, RouteConfig } from 'vue-router';
import Home from './views/Home.vue';
import Whatsup from './components/Whatsup.vue';
import SearchData from './components/SearchData.vue';
import Login from './components/Login.vue';
import Service from './components/Service.vue';
import Feeds from './components/Feeds.vue';
import Gallery from './components/Gallery.vue';
import DynComponent from './components/DynComponent.vue';
import Calender from './components/Calender.vue';
import WebRtc from './components/WebRtc.vue';
import GridComponent from './components/GridComponent.vue';
import SrcResultComponent from './components/SrcResultComponent.vue';


class StefRouter extends Router {
  _options: RouterOptions;

  constructor(options?: RouterOptions) {
    super(options);
    this._options = options as RouterOptions;
  }

  public stefsRoutes(): RouteConfig[] {
    return this._options.routes as RouteConfig[];
  }
}

const router = new StefRouter({
  routes: [

    {
      path: '/',
      name: 'stefshome',
      component: Whatsup,
    },

    {
      path: '/whatsup',
      name: 'whatsup',
      component: Whatsup,
    },
    { path: '/wasistlos', name: 'Was ist los', redirect: '/whatsup' },
    { path: '/dyn/:base_id/:modus/:typ', component: DynComponent, props: true },
    { path: '/dynchild/:base_id/:modus/:typ/:value_id', component: DynComponent, props: true },
    {
      path: '/login', name: 'login', component: Login, props: true,
    },
    { path: '/service', component: Service },
    { path: '/feeds/:base_id', component: Feeds },
    { path: '/webrtc', component: WebRtc },
    { path: '/gallery/:base_id', component: Gallery },
    { path: '/days/:day/:ansicht/:direction', name: 'days', component: Calender },
    { path: '/grid/:typ', name: 'grid', component: GridComponent },

    { path: '/srch/:srchtext/:typ/:searchtype', name: 'linksrch', component: SrcResultComponent },
    {
      path: '/kalender',
      name: 'Kalender',
      redirect:
        `/days/${
          new Date().getFullYear()
        }-${
          new Date().getMonth() + 1
        }-${
          new Date().getDate()
        }/1/0`,
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    },


  ],
});

export default router;
