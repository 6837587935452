







































import { Component, Prop, Vue } from "vue-property-decorator";
import { IStrDetailValue, IFeed, IInfo } from "@/Services/Interfaces";
import store from "../store";

import { globalInfoService } from "../Services/GlobalService";
import { dataService } from "../Services/DataServices";

@Component
export default class Feeds extends Vue {
  Feeds: IFeed[];
  title: string;
  _model: IStrDetailValue;
  constructor() {
    super();
    this.name = "Feeds";

    this.Feeds = new Array<IFeed>();
    this.title = "";
    this._model = <IStrDetailValue>{};
  }

  name: string;

  get model() {
    return this._model;
  }

  set model(value: IStrDetailValue) {
    this._model = value;
  }

  mounted() {
    if (this.$route.params && this.$route.params.base_id) {
      var baseid = Number(this.$route.params.base_id);
      dataService.InitbyToken(store.getters.user.token);

      var url =
        "Service/GetDetailValues/" + baseid + "/" + store.getters.user.token;
      dataService.InitbyToken(store.getters.user.token);
      dataService.get<IStrDetailValue[]>(url).then(
        (g) => {
          if (g.length > 0) {
            this.title = g[0].strvalue;
          }
          var res = g.filter((t) => t.valuetyp.toLowerCase() === "feed");
          if (res.length > 0) {
            this.model = res[0];
            this.init();
          } else {
            throw "Feed not found";
          }
        },
        (e) => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Getting details for feeds",
            error: e,
          });
        }
      );
    } else {
      this.init();
    }
  }

  init(): void {
    var url = "Service/getfeeds";

    dataService.InitbyToken(store.getters.user.token);
    var postparam = this.model as IStrDetailValue;
    dataService.post<IStrDetailValue, IFeed[]>(url, postparam).then(
      (g) => {
        this.Feeds = g;
      },
      (e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Getting feeds",
          error: e,
        });
      }
    );
  }
}
