












import Menue from './components/Menue.vue';


export default {
  name: 'app',
  components: {
    Menue,
    Intro: () => import('./components/Intro.vue')
  },
  created() {
    
  },
};
