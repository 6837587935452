// @ts-ignore


import router from '../router';
import DataServices, { dataService } from './DataServices';
import {
  IUser, ILoginparam, IInfo, IWhatsup, INav, IToken, IStrValue, IEmail, IStrDetailValue, IBaseResult, IBaseObjectResult, IAppControl, Iappointment, IEncrypt, IKurs, ITopNew, IKursPrediction, IRelation,
} from './Interfaces';
import { indedbService } from '../api/indexdbService';
import BaseService from './BaseService';


import {
  globalInfoService, globalWhatsUpService, globalUserService, globalReceiveDetailsService, globalTopNewsReceiveService, globalappcontrolService, globalAppointmentService, globalKurseUpService, globalKursePredictionService, globalInnerLinkService,
} from './GlobalService';
import store from '../store';
import { InfoType } from './enums';
import { HubConnection } from '@microsoft/signalr';


// https://johnpapa.net/vue-typescript/
export default class LoginViewModel extends BaseService {


  getPicture(): Promise<IBaseObjectResult> {
    if (store.state.picture !== undefined) {
      return Promise.resolve(store.state.picture);
    }
    const url = `Service/GetPicture/${this.user.email}`;
    dataService.InitbyToken(this.user.token);
    const promise = dataService.get<IBaseObjectResult>(url);

    promise.catch((r) => {
      console.debug(r.toString());
    });
    promise.then((t) => {
      store.state.picture = t;
    });

    return promise;
  }


  baseurl: string;

  emails: IEmail[];

  logoutsucessevent(logoutevent: Event): void {
    globalInfoService.sendMessage(<IInfo>{ msg: 'Good bye....', type: InfoType.info });
    this.user = <IUser>{};
    store.dispatch('user', this.user);
    window.location.assign('http://stefhorst.de');
    indedbService.deleteDB();

  }


  logouterrorevent(logoutevent: Event): void {
    this.user = <IUser>{};
    store.dispatch('user', this.user);
    globalInfoService.sendMessage(<IInfo>{ msg: 'Logged out', error: 'Error while getting out here.' });
  }


  logOut(): void {
    globalInfoService.sendMessage(<IInfo>{ msg: 'Try logging out', type: InfoType.info });

    this.GetUser().then((user) => {
      this.data.InitbyToken(user.token);
      this.data.get<any>('Service/logout').then((token) => {
        indedbService.db().then(d=>
          {
            d.close();
            const request = indedbService.deleteDB();
            request.onsuccess = this.logoutsucessevent;
            request.onerror = this.logouterrorevent;
          });

        globalInfoService.sendMessage(<IInfo>{ msg: 'Logging out', type: InfoType.loading });
      }, (e) => {
        globalInfoService.sendMessage(<IInfo>{ msg: 'Error Logging out', error: e });
      });
    }, (e) => {
      globalInfoService.sendMessage(<IInfo>{ msg: 'Error while Logging out', error: e });
    });
  }


  hasSignalConnection: boolean = false;

  email: string;

  psw: string;

  title: string;

  encryptcode: string;

  data = new DataServices();


  user: IUser;

  naventries: Array<INav>;

  typen: Array<string>;

  connection: HubConnection;

  constructor() {
    super();
    this.email = '';
    this.psw = '';
    this.encryptcode = '';
    this.title = 'Login';
    this.baseurl = '';
    this.naventries = Array<INav>();
    this.connection = <HubConnection>{};

    this.user = <IUser>{};
    this.typen = new Array<string>();
    this.emails = new Array<IEmail>();

    router.beforeEach((to, from, next) => {
      if (to.query.token != undefined && to.query.token.length > 0 ) 
      {
          this.TryLoginwithToken(to.query.token).then((us) => {            
            this.user = us;
            this.user.token = to.query.token.toString();                        
            globalInfoService.sendMessage(<IInfo>{ msg: 'Hallo ' + us.name, error: '', type: InfoType.success });     
            next({
              path: '/whatsup',
              query: {},
            });
          }).catch(g => {
            globalInfoService.sendMessage(<IInfo>{ msg: 'Anmeldung ist abgelaufen....', error: g });
            next({
              path: '/login',
              query: { to: to.fullPath },
            });
          });    
  
      }
      else if (!this.loggedin() && to.path !== '/login') {   
              
        this.TryLogin().then(user => {
          globalInfoService.sendMessage(<IInfo>{ msg: '' });
          globalUserService.sendMessage(user);
          if (user.token) 
          {
            this.getTypen(user.token);
          }
           
          next();
        }).catch(g => {
          console.info(`before fullpath to ${to.fullPath}`);
          next({
            path: '/login',
            query: { to: to.fullPath, token: to.query.token },
          });
        });
      } else {
        next();
      }
    });

    router.afterEach((g) => {
      globalInfoService.sendMessage(<IInfo>{});

      if (g.fullPath.indexOf('srch/') < 0) {
        globalappcontrolService.sendMessage(<IAppControl>{
          searchmenuenabled: true,
        });
      }
    });
  }

  loggedin(): boolean {
    return store && store.getters && store.getters.user !== undefined && store.getters.user.token && store.getters.user.token.length > 0;
  }

  CanSaveEnccryptCode(): boolean {
    if (!this.user) return false;
    if (!this.encryptcode) return false;
    if (this.encryptcode.length < 3) return false;
    return true;
  }


  /** Schlüssel festlegen für sichere Übertragenung */
  onSaveEnccryptCode(): void {
    if (!this.user) return;
    const dbservice = indedbService;

    const value = dbservice.db().then((db) => {
      db.delete('Kennung', this.user.base_id).then((delted) => {
        const kennung = <IEncrypt>{
          base_id: this.user.base_id,
          key: this.encryptcode,
        };

        db.put('Kennung', kennung).then((q) => {
          //
          globalInfoService.sendMessage(<IInfo>{ msg: 'Encyrptcode gesichert..', type: InfoType.info });
        }).catch((r) => {
          globalInfoService.sendMessage(<IInfo>{ msg: 'Storing encypt key fails', error: r });
        });
      }).catch((de) => {
        globalInfoService.sendMessage(<IInfo>{ msg: 'Reinigen store', error: de });
      });

    }).catch((e) => {
      globalInfoService.sendMessage(<IInfo>{ msg: 'Init db fails', error: e });
    });
  }

  hasPermission(permtext: string): boolean {
    if (this.user == undefined) return false;
    if (this.user.email == undefined) return false;
    if (this.user.email.toLowerCase() == 'stefhorst@hotmail.com') return true;
    return false;
  }


  getEmails(): Promise<IEmail[]> {
    const url = 'Service/GetEmails';
    dataService.InitbyToken(this.user.token);
    const p = dataService.get<IEmail[]>(url);
    p.then((g) => {
      this.emails = g.map(f => <IEmail>
        {
          name: f.name,
          email: f.email,
          gif: f.gif,
          emailname: `${f.name} [${f.email}]`,
        });

      store.dispatch('emails', this.emails);
    }).catch((e) => {
      globalInfoService.sendMessage(<IInfo>{ msg: 'Load Emails fails...', error: e });
    });
    return p;
  }


  getTypen(token: string): Promise<any> {
    this.typen = Array<string>();
    const url = 'Service/getTypen';
    dataService.InitbyToken(token);
    const p = dataService.get<string[]>(url)
      .then((g) => {
        this.typen = g;
        store.dispatch('typen', g);
        return g;
      }).catch((e) => {
        globalInfoService.sendMessage(<IInfo>{ msg: 'Load Typen fails...', error: e });
      });
    return p;
  }


  createNaventries(user: IUser): void {
    this.naventries = new Array<INav>();

    const url = 'Service/GetValues?str_value=[Favorit:Ja]&typname=Links';
    dataService.InitbyToken(user.token);
    dataService.get<IStrValue[]>(url).then((g) => {
      this.naventries = g.map(h => <INav>{
        base_id: h.base_id,
        title: h.strvalue,
        modus: 'noedit',
        typ: 'Links',
      });
    }).catch((e) => {
      globalInfoService.sendMessage(<IInfo>{ msg: 'Menue load fails', error: e });
    });
  }

  /** Return des angemeldete Useers */
  public GetUser(): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      indedbService.db().then((d) => {
        d.getAll('Login').then((users) => {
          if (users.length > 0 && users[0].token) {
            d.close();
            resolve(users[0]);
          } else {
            reject(new Error('User wurde nicht gefunden / nicht angemeldet. ?'));
          }
        });
      }, (e) => {
        reject(e);
      });
    });
  }

  TryLoginwithToken(token: any): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      {        
        store.dispatch("clear");
        dataService.InitbyToken(token);
        dataService.get<IUser>(`Service/GetUserInfos`).then((u) => {
          u.token = token;
          store.dispatch('user', u);
          this.user = u;
          this.email = u.email;          
          globalUserService.sendMessage(this.user);          
          const value = indedbService.db()
            .then((db) => {
              db.put('Login', u).then((q) => {  
                            
                this.hasSignalConnection = false;
                this.initSignal(token);
                this.createNaventries(u);
                this.getTypen(token);         
                db.close();                 
                
                resolve(u);
              }).catch((r) => {
                globalInfoService.sendMessage(<IInfo>{ msg: 'Storing user fails', error: r });
              });
            }).catch((e) => {
              globalInfoService.sendMessage(<IInfo>{ msg: 'Init db fails', error: e });
            });
        }).catch((e) => {
          globalInfoService.sendMessage(<IInfo>{ msg: 'Fehler : Init Link ', error: e,  type: InfoType.error  });
          reject(new Error('User für Anmeldung nicht gefunden oder nicht mehr gültig.'));
        });
      }
    })
  }

  TryLogin(): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      indedbService.db().then((d) => {
        d.getAll('Login').then((users) => {
          
          store.dispatch("clear");
          if (users.length == 1 && users[0].token) {
            this.user = users[users.length - 1];
            d.close();
            store.dispatch('user', this.user);
            resolve(this.user);
          } else {
            users.forEach((u) => {
              d.delete('Login', u.base_id);
            });

            reject(new Error('User not identified....Login again...'));
          }

        }).catch((ex) => {

          reject(ex);
        });
      }).catch((e) => {
        // router.push('/login');
        // router.push({ path: '/login', query: { to: tourl }});
        reject(e);
      });
    });
  }


  refreshonServer(user: IUser): Promise<IBaseResult> {
    
      this.data.InitbyToken(user.token);    
      return this.data.get<IBaseResult>('service/start/' + this.hasSignalConnection)
        .then((result) => {
          console.debug(`${result.error} ${result.message}`);
          return result;
        }, (e) => {
          console.debug(`could not start refresh ${e}`);
          this.initSignal(user.token);
          return <IBaseResult> {error: e.Exception, message: "Fehler Aktualisieren der Meldungen"};          
        });
      
  }


  initSignal(token: string): void {
    this.connection = <HubConnection>{};
    this.connection = dataService.CreateConnection(token);

    this.connection.on('updateKursinfos', (details: ITopNew[]) => {

      details.forEach((w) => {
        w.Typ = "kursinfo";
        globalTopNewsReceiveService.sendMessage(<ITopNew>w);
      });
    });

    this.connection.on('updateInnerLinks', (details: IRelation[]) => {      
      globalInnerLinkService.sendMessage(details);

    });

    this.connection.on('updateKursPrediction', (details: IKursPrediction[]) => {

      console.debug(" prediction ?? " + details.length);
      globalKursePredictionService.sendMessage(details);

    });


    this.connection.on('updateTopNews', (details: ITopNew[]) => {
      // console.debug("event topnews " + JSON.stringify(details))
      details.forEach((w) => {
        globalTopNewsReceiveService.sendMessage(<ITopNew>w);
      });
    });

    this.connection.on('updateKurse', (details: IKurs[]) => {

      globalKurseUpService.sendMessage(details);
    });


    this.connection.on('updateWhatsups', (details: IWhatsup[]) => {
      
        globalWhatsUpService.sendMessage(details);
      
    });

    this.connection.on('updateDetails', (details: IStrDetailValue[]) => {
      details.forEach((w) => {
        globalReceiveDetailsService.sendMessage(<IStrDetailValue>w);
      });
    });

    this.connection.on('updateTermine', (details: Iappointment[]) => {

      globalAppointmentService.sendMessage(<Iappointment[]>details);

    });


    this.connection.start().then((g: any) => {
      // globalInfoService.sendMessage(        <IInfo> { msg:  "hub connected " + this.connection.state.toString(),        type : InfoType.info });
      console.debug(`hub started ${this.connection.state.toString()}`);
      this.hasSignalConnection = true;
    }).catch((g) => {
      this.hasSignalConnection = false;
      globalInfoService.sendMessage(
        <IInfo>{
          msg: 'Start hub fails',
          error: `hub fails ${g.toString()}`,
          type: InfoType.debug,
        },
      );
    });
  }

  onLogin(event: any, to: string): void {
    try {       
      console.info(`onlogin to  ${to}`);
      const dbservice = indedbService;
      this.title = 'Log in';
      this.data.Init(this.email, this.psw);

      this.data.get<IToken>('Service/InitbyEmail').then((token) => {
        this.data.InitbyToken(token.token);

        this.data.get<IUser>(`Service/GetUserInfos`)
          .then((result) => {
              this.hasSignalConnection = false;
              this.user = result;
              this.user.token = token.token;
              store.dispatch('user', this.user);              
              this.createNaventries(this.user);
              this.getTypen(this.user.token);
              this.initSignal(this.user.token);
            
            if (result) {
              const value = dbservice.db()
                .then((db) => {
                  db.put('Login', result).then((q) => {
                    db.close();
                    
                    if (to !== undefined) {
                      console.info(` goto > ${to}`);
                      router.push(to);
                    } else {
                      router.push('/');
                    }
                    globalUserService.sendMessage(this.user);
                  }).catch((r) => {
                    globalInfoService.sendMessage(<IInfo>{ msg: 'Storing user fails', error: r });
                  });
                }).catch((e) => {
                  globalInfoService.sendMessage(<IInfo>{ msg: 'Init db fails', error: e });
                });
            }
          }).catch((e) => {
            globalInfoService.sendMessage(<IInfo>{ msg: 'Getting Userdata fails', error: e });
          });
      }).catch((e) => {
        globalInfoService.sendMessage(<IInfo>{ msg: 'Login failed', error: e , type: InfoType.finished});
        const request = indedbService.deleteDB();
      });
    } catch (ex) {
      globalInfoService.sendMessage(<IInfo>{ msg: 'Login error', error: ex });
      const request = indedbService.deleteDB();
    }
  }

  ongetLog(): void {
    if (this.user) {
      router.push('/grid/log');
    }
  }

  canShowLog(): boolean {
    return this.hasPermission('canShowLog');
  }

  canDataSave(): boolean {
    return this.hasPermission('CanDataSave');
  }


  canNeuStart() {
    return this.hasPermission('CanNeuStart');
  }


  AllowSaveEnccryptCode() {
    return this.hasPermission('AllowSaveEnccryptCode');
  }

  onClose() {
    this.logOut();
  }

  onNeuStart() {
    dataService.InitbyToken(this.user.token);
    const url = 'Service/DoCommand/restart';
    dataService.get<IBaseResult>(url).then((d) => {
      globalInfoService.sendMessage(<IInfo>{ msg: ' :) ', error: '' });
    }).catch((e) => {
      globalInfoService.sendMessage(<IInfo>{ msg: 'Start server error', error: e });
    });
  }


  canEinstellungen(): boolean {
    return this.user && this.user.base_id_einstellungen > 0;
  }

  onEinstellungen(): void {
    router.push(`/dyn/${this.user.base_id_einstellungen}/update/Einstellungen`);
  }


  onDataSave() {
    dataService.InitbyToken(this.user.token);
    const url = 'Service/DoCommand/backup';
    dataService.get<IBaseResult>(url).then((d) => {
      globalInfoService.sendMessage(<IInfo>{ msg: ' :) ', error: '' });
    }).catch((e) => {
      globalInfoService.sendMessage(<IInfo>{ msg: 'Save backuped error', error: e });
    });
  }
}

export const loginViewModel = new LoginViewModel();
