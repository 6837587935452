
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { async } from 'rxjs/internal/scheduler/async';
import {
  IStoreState as RootState, IUser, IEmail, IWhatsup, IFullText, IBaseObjectResult, IKurs, Iappointment, ITopNew, IDay, IKursPrediction,
} from './Services/Interfaces';
import { KursTypObject } from './Services/enums';

// https://codeburst.io/vuex-and-typescript-3427ba78cfa8

Vue.use(Vuex);

export default new Vuex.Store({

  // initial state
  
  state: {
    version: '1.0.0', // a simple property.
    emails: new Array<IEmail>(),
    user: <IUser>{},
    typen: <string[]>{},
    favoritetypen: <string[]>{},
    srchtxt: '',
    picture: <IBaseObjectResult>{},
    whatsups: new Array<IWhatsup>(),
    topnews: new Array<ITopNew>(),
    termine: new Array<Iappointment>(),
    days: new Array<IDay>(),
    kurse: new Array<IKurs>(),
    kursprediction: new Array<IKursPrediction>(),
    kurstypen: new Array<KursTypObject>(),

    array: <any>[],
    columns: <any>[],
    srchlist: new Array<IFullText>(),
  },
  getters: {

    array: state => state.array,
    columns: state => state.array,
    srchlist: state => state.srchlist,
    whatsups: state => state.whatsups,
    topnews: state => state.topnews,
    termine: state => state.termine,
    //days: (state) => () => { state.days },
    days: state => state.days,
    kurse: state => state.kurse,
    kursprediction: state => state.kursprediction,
    kurstypen: state => state.kurstypen,
    srchtext: state => state.srchtxt,

    typen: state => state.typen,
    emails: state => state.emails,
    user: state => state.user,

  },

  actions: {
    clear(context) 
    {      
      context.commit('user', <IUser>{});
      context.commit('days', new Array<IDay>());
      context.commit('emails', new Array<IEmail>());
      context.commit('whatsups', []);
      context.commit('termine', []);
      
    },
    // SET_NAME : async (context, name) => {

    user(context, user: IUser) {
      context.commit('user', user);
    },

    emails(context, es: IEmail[]) {
      context.commit('emails', es);
    },

    srchlist(context, srchlist: Array<IFullText>) {
      context.commit('srchlist', srchlist);
    },

    typen(context, typen: Array<string>) {
      context.commit('typen', typen);
    },

    favoritetypen(context, typen: Array<string>) {
      context.commit('favoritetypen', typen);
    },
    whatsups(context, whs: Array<IWhatsup>) {
      context.commit('whatsups', whs);
    },
    topnews(context, whs: Array<ITopNew>) {
      context.commit('topnews', whs);
    },
    kurse(context, ks: Array<IKurs>) {
      context.commit('kurse', ks);
    },
    kursprediction(context, ks: Array<IKursPrediction>) {
      context.commit('kursprediction', ks);
    },

    kurstypen(context, ks: Array<KursTypObject>) {
      context.commit('kurstypen', ks);
    },
    days(context, ds: Array<IDay>) {
      context.commit('days', ds);
      console.debug("commit days");
    }
    ,
    day(context, w: Iappointment) {
        var days = context.state.days;
        for (let indexday = 0; indexday < days.length; indexday++) {
            const day = days[indexday];

            var index = day.appointments.findIndex(
              (g : Iappointment)  => g.base_id == w.base_id
            );

            if (index > -1) {
              Vue.set(days[indexday].appointments, 0, w);
              if (w.crud == "delete") {
                days[indexday].appointments.splice(index, 1);
                return;

              } else {
                Vue.set(days[indexday].appointments, index, w)
                // days[indexday].appointments[index] = w;
                 console.debug("update Termin im Kalender" + JSON.stringify(w) );
                 return;
              }

            } else {
              if ( new Date(day.date) == new Date(w.date)) {
              if ( !w.crud ||  w.crud == "create" ) {
                days[indexday].appointments.unshift(w);
                return;
              }
            }
          }
        }

    }
    ,
    termine(context, whs: Array<Iappointment>) {
      context.commit('termine', whs);
    },
    changewhatsup(context, wh: IWhatsup) {
      context.commit('changewhatsup', wh);
    },

    deletewhatsup(context, wh: IWhatsup) {
      context.commit('deletewhatsup', wh);
    },

    srchtext(context, s) {
      context.commit('srchtext', s);
    },


  },
  mutations: {

    deletewhatsup(state, w: IWhatsup) {
      const index = state.whatsups.findIndex(g => g.Id === Number(w.Id));
      if (index > -1) {
        state.whatsups.splice(index, 0);
      }
    },
    changewhatsup(state, w: IWhatsup) {
      const index = state.whatsups.findIndex(g => g.Id === Number(w.Id));
      let deleted = false;
      if (index > -1) {
        const clientwhatsup = state.whatsups[index];
        if (w.crud == undefined) {
          w.crud = clientwhatsup.crud == 'create' ? 'update' : 'create';
          state.whatsups[index] = w;
        } else if (w.crud === 'delete') {
          state.whatsups.splice(index, 0);
          // state.whatsups[index] = w;
          deleted = true;
        } else {
          w.crud = 'update';
          state.whatsups[index] = w;
        }
      } else {
        w.crud = 'create';
        state.whatsups.unshift(w);
      }
    },
    topnews(state, top) {
      state.topnews = top;
    },
    whatsups(state, whatsons) {
      state.whatsups = whatsons;
    },

    termine(state, r) {
      state.termine = r;
    },
    days(state, r) {
      state.days = r;
    },

    kurse(state, k) {
      state.kurse = k;
    },

    kursprediction(state, k) {
      state.kursprediction = k;
    },


    kurstypen(state, k) {
      state.kurstypen = k;
    },

    srchlist(state, s) {
      state.srchlist = s;
    },

    emails(state, es) {
      state.emails = es;
    },

    srchtext(state, s) {
      state.srchtxt = s;
    },

    user(state, user: IUser) {
      state.user = user;
    },

    typen(state, typen: []) {
      state.typen = typen;
    },

    favoritetypen(state, typen: []) {
      state.favoritetypen = typen;
    },


  },
});
