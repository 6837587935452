






















































































































































































import Vue from "vue";

import { dataService } from "../Services/DataServices";

import {
  globalWhatsUpService,
  globalUserService,
  globalInfoService,
  globalappcontrolService,
  globalLastSrchService,
} from "../Services/GlobalService";


import { Component, Prop } from "vue-property-decorator";
import router from "../router";
import store from "../store";
import { InfoType, SearchType } from "../Services/enums";
import {
  IInfo,
  IStrValue,
  ISrchValue,
  IUser,
  IAppControl,
  IReportOutput,
  IOutput,
  IFullText,
} from "../Services/Interfaces";
import { loginViewModel } from "../Services/LoginViewModel";

@Component
export default class SrcResultComponent extends Vue {
  typen: string[];

  selectedtypen: string[];
  typ: string;
  user: IUser;
  searchtype: SearchType;
  constructor() {
    super();
    this.viewmodel = new Array<IStrValue>();
    this.msg = "";
    this.typ = "";
    this.srchtext = "";
    this.typename = "";
    this.typen = new Array<string>();
    this.selectedtypen = [];
    this.user = <IUser>{};
    this.searchtype = SearchType.unbekannt;
  }

  /*

CREATE UNIQUE INDEX fullindexstrvalue ON dbo.tbl_values (id) ;

CREATE FULLTEXT INDEX ON dbo.tbl_values(strvalue)
   KEY INDEX fullindexstrvalue
   WITH STOPLIST = SYSTEM;
CREATE FULLTEXT INDEX ON dbo.tbl_values(memovalue)
   KEY INDEX fullindexstrvalue
   WITH STOPLIST = SYSTEM;

    */
  canPrint(): boolean {
    if (store.getters.user == undefined) return false;
    if (store.getters.user.email == undefined) return false;
    return (
      this.selectedtypen &&
      this.selectedtypen.length == 1 &&
      store.getters.user.email.toLowerCase() == "stefhorst@hotmail.com"
    );
  }

  canExcel(): boolean {
    return this.canPrint();
  }

  canReport(): boolean {
    if (store.getters.user == undefined) return false;
    if (store.getters.user.email == undefined) return false;
    return (
      this.selectedtypen &&
      this.selectedtypen.length == 1 &&
      store.getters.user.email.toLowerCase() == "stefhorst@hotmail.com"
    );
  }

  forward(): void {
    var max = Math.max(...this.viewmodel.map((d) => d.base_id));
    this.search(this.selectedtypen, max);
  }
  bettersearch(): void {
    if (this.srchtext.indexOf("*") > -1) {
      this.srchtext = this.srchtext.replace("*", "").replace("*", "");
      this.srchtext = "%" + this.srchtext + "%";
      this.srchtext = this.srchtext.replace("%%", "%");
    } else if (this.srchtext.indexOf("%") > -1) {
      this.srchtext = this.srchtext.replace("%", "").replace("%", "");
      this.srchtext = "*" + this.srchtext + "*";
      this.srchtext = this.srchtext.replace("**", "*");
    } else {
      this.srchtext = "*" + this.srchtext.trim() + "*";
    }
    this.search(this.selectedtypen, 0);
  }

  print(): void {
    if (!this.selectedtypen || this.selectedtypen.length > 1) return;

    globalInfoService.sendMessage(<IInfo>{
      msg: "Pdf wird erstellt.......",
      error: null,
      type: InfoType.loading,
    });

    dataService.InitbyToken(this.user.token);
    dataService
      .post<ISrchValue, IOutput>("Service/LoadPDFData", <ISrchValue>{
        strvalue: this.srchtext,
        typenames: this.selectedtypen,
      })
      .then((d) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: d.label,
          error: d.error,
          type: InfoType.info,
          link: d.link,
        });
      })
      .catch((ex) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Sorry.... PDF wurde nicht gemacht... :(",
          error: ex,
          type: InfoType.error,
        });
      });
  }

  excel(): void {
    if (!this.selectedtypen || this.selectedtypen.length > 1) return;

    globalInfoService.sendMessage(<IInfo>{
      msg: "Excel wird erstellt.......",
      error: null,
      type: InfoType.loading,
    });

    dataService.InitbyToken(this.user.token);
    dataService
      .post<ISrchValue, IOutput>("Service/LoadExcelData", <ISrchValue>{
        strvalue: this.srchtext,
        typenames: this.selectedtypen,
      })
      .then((d) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: d.label,
          error: d.error,
          type: InfoType.info,
          link: d.link,
        });
      })
      .catch((ex) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Sorry.... Excel wurde nicht gemacht... :(",
          error: ex,
          type: InfoType.error,
        });
      });
  }

  report(): void {
    if (!this.selectedtypen || this.selectedtypen.length > 1) return;

    globalInfoService.sendMessage(<IInfo>{
      msg: "Report wird erstellt.......",
      error: null,
      type: InfoType.loading,
    });

    dataService.InitbyToken(this.user.token);
    dataService
      .post<ISrchValue, IReportOutput>("Service/LoadReportData", <ISrchValue>{
        strvalue: this.srchtext,
        typenames: this.selectedtypen,
      })
      .then((d) => {
        store.state.array = d.array;
        store.state.columns = d.columns;
        router.push("/grid/report");

        globalInfoService.sendMessage(<IInfo>{
          msg: d.label,
          error: d.error,
          type: d.error ? InfoType.warning : InfoType.info,
          link: d.link,
        });
      })
      .catch((ex) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Sorry.... Ausgabe wurde nicht gemacht... :(",
          error: ex,
          type: InfoType.error,
        });
      });
  }

  data() {
    return {
      selectedtypen: this.selectedtypen,
      typen: this.typen,
      top: 0,
      searchtype: SearchType.unbekannt,
    };
  }

  remove(item: any) {
    this.selectedtypen.splice(this.selectedtypen.indexOf(item), 1);
    this.selectedtypen = [...this.selectedtypen];
  }

  newItem(): void {
    if (!this.canNeu()) return;
    router.push("/dyn/" + -1 + "/create/" + this.selectedtypen[0]);
  }

  canNeu(): boolean {
    return this.selectedtypen != undefined && this.selectedtypen.length == 1;
  }

  changeClick(e: any): void {
    if (e && e.length == 0) return;
    this.search(e, 0);
  }

  onRowSelected(record: any) {
    
    router.push("/dyn/" + record.base_id + "/update/record");
  }

  get viewmodels(): IStrValue[] {
    return this.viewmodel;
  }

  beforeDestroy(): void {}

  viewmodel: Array<IStrValue>;
  msg: string;
  srchtext: string;
  typename: string;
  fields = [
    {
      value: "base_identifier",
      text: "Name",
      sortable: true,
      cols: 6,
      align: "left",
      filterable: true,
    },
    {
      value: "strvalue",
      text: "Text found",
      sortable: true,
      cols: 4,
      filterable: false,
    },
    {
      value: "typname",
      text: "Typ",
      cols: 2,
      sortable: true,
      filterable: false,
    },
  ];

  edit(baseid: number): void {
    router.push("/dyn/" + baseid + "/update/record");
  }

  destroyed(): void {
    globalappcontrolService.sendMessage(<IAppControl>{
      searchmenuenabled: true,
    });
  }

  created(): void {}

  search(selectedtypen: string[] | string, smallerthanbaseid: number): void {
    if (
      !selectedtypen ||
      selectedtypen == undefined ||
      selectedtypen.length == undefined ||
      selectedtypen.length == 0
    ) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Typ für Suche unbekannt ",
        error: "",
      });
      return;
    }

    globalInfoService.sendMessage(<IInfo>{
      msg: "",
      error: null,
      type: InfoType.loading,
    });

    globalappcontrolService.sendMessage(<IAppControl>{
      searchmenuenabled: false,
    });

    var p = <ISrchValue>{
      smallerthanbaseid: smallerthanbaseid,
      searchtype: this.searchtype,
      strvalue: this.srchtext,
      typenames: selectedtypen,
      top:
        this.srchtext == undefined ||
        this.srchtext.length == 0 ||
        this.srchtext == "*" ||
        this.srchtext == "%"
          ? 100 * 10
          : 0,
    };
    dataService.InitbyToken(this.user.token);
    dataService
      .post<ISrchValue, IStrValue[]>("Service/GetValues", p)
      .then((d) => {
        if (
          this.searchtype == SearchType.FullText ||
          this.searchtype == SearchType.LastSearch
        ) {
          this.selectedtypen = new Array<string>();
          d.forEach((element) => {
            if (
              this.selectedtypen.filter((t) => t == element.typname).length == 0
            ) {
              this.selectedtypen.push(element.typname);
            }
          });
        }

        this.searchtype = 0;

        globalInfoService.sendMessage(<IInfo>{
          msg:
            d.length > 500
              ? "Mal eben " + d.length + " Daten gefunden .. :)"
              : "",
          error: null,
          type: InfoType.finished,
        });
        this.viewmodel = d;
        store.state.favoritetypen = this.selectedtypen;
        store.dispatch("srchtext", this.srchtext);

        if (this.srchtext.length > 1 && d.length > 0) {
          var cleeard = this.srchtext
            .replace("%", "")
            .replace("*", "")
            .replace("%", "")
            .replace("*", "")
            .replace("%", "")
            .replace("*", "");
          globalLastSrchService.sendMessage(<IFullText>{
            search: cleeard,
            keyword: cleeard,
            searchtype: SearchType.LastSearch,
            typnames: this.selectedtypen,
            description: "Erneut suchen..",
            gif: this.getSearchIcon(),
          });
        }
      })
      .catch((e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Sorry, Fehler beim Suchen ",
          error: e,
        });
      });
  }

  getSearchIcon(): any {
    return require("../assets/repeat.png");
  }

  mounted(): void {
    try {
      this.searchtype = Number(this.$route.params.searchtype)
        ? Number(this.$route.params.searchtype)
        : 0;
      this.srchtext =
        this.$route.params.srchtext == undefined
          ? store.getters.srchtxt
          : this.$route.params.srchtext.toString();

      if (
        this.$route.params.srchtext &&
        this.$route.params.srchtext.toString() == "all"
      ) {
        this.srchtext = "%";
      } else if (
        this.$route.params.srchtext &&
        this.$route.params.srchtext.indexOf("*") == -1
      ) {
        this.srchtext = "*" + this.$route.params.srchtext + "*";
      }
      this.typ = this.$route.params.typ;
      this.msg = "";
      if (
        this.typen &&
        this.typen.length > 0 &&
        store.getters.favoritetypen &&
        store.getters.favoritetypen.length > 0
      ) {
        this.selectedtypen = store.getters.favoritetypen;
        this.search(this.selectedtypen, 0);
      } else {
        loginViewModel.GetUser().then((user) => {
          this.user = user;
          loginViewModel.getTypen(user.token).then((t) => {
            this.typen = t;
            this.selectedtypen =
              this.typ != undefined && this.typ.length > 2
                ? this.typ.split(";")
                : store.getters.favoritetypen &&
                  store.getters.favoritetypen.length > 0
                ? store.getters.favoritetypen
                : <string[]>["Adresse"];
            this.search(this.selectedtypen, 0);
          });
        });
      }
    } catch (e) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Sorry, Fehler beim Starten der Suche ... ",
        error: e,
      });
    }
  }
}

