import { Subject } from 'rxjs';
import { IInfo } from './Interfaces';

export default class BaseService {
    public infosubject: Subject<IInfo>;

    constructor() {
      this.infosubject = new Subject();
    }
}

export const baseservice = new BaseService();
