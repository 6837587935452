















































































































import { Component, Prop, Vue } from "vue-property-decorator";

import { loginViewModel } from "../Services/LoginViewModel";
import { dataService } from "@/Services/DataServices";
import { globalInfoService } from "@/Services/GlobalService";
import { IInfo } from "@/Services/Interfaces";
import { InfoType } from "../Services/enums";
import router from "@/router";

@Component
export default class Login extends Vue {
  to: any;
  loading: boolean;
  token: any;

  constructor() {
    super();
    this.name = "Login";
    this.loading = false;
  }

  viewmodel: any;

  mounted(): void {
    this.loading = true;
    this.to = this.$route.query.to;
    this.token = this.$route.query.token;    
    
    if (!this.viewmodel.user) return;
    dataService
      .GetEncryptCode(this.viewmodel.user.base_id)
      .then((g) => {
        this.viewmodel.encryptcode = g.key;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        globalInfoService.sendMessage(<IInfo>{
          msg: "Cryptinfo holen ging nicht",
          type: InfoType.debug,
          error: e,
        });
      });
  }

  data() {
    return {
      viewmodel: loginViewModel,
      to: "",
      token: ""
    };
  }

  name: {};
}
