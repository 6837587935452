






































import Vue from 'vue';
// @ts-ignore
import { loginViewModel } from '../Services/LoginViewModel.ts';

export default {

  data() {
    return {
      viewmodel: loginViewModel,
    };
  },
  name: 'service',
  components: {},
};
