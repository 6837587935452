

























































import Vue from 'vue';


import { Component, Prop } from 'vue-property-decorator';

import router from '@/router';
import { globalInfoService } from '../Services/GlobalService';
import { IInfo, IStrDetailValue } from '../Services/Interfaces';
import { InfoType } from '../Services/enums';
import { dataService } from '../Services/DataServices';
import store from '../store';

@Component
export default class GridComponent extends Vue {
  constructor() {
    super();
    this.viewmodel = [];
    this.msg = '';
  }

  onRowSelected(records: any[]) {
    if (records.length > 0) {
      router.push(`/dyn/${records[0].base_id}/update/typ`);
    }
  }

  viewmodel: IStrDetailValue[] = [];

  msg: string;

  fields: string[] = [];

  edit(baseid: number): void {
    //  router.push("/dyn/" + baseid + "/update");
  }

  created(): void {}

  mounted() {
    const typ = this.$route.params.typ.toString();

    if (typ.toLowerCase() == 'log') {
      dataService.InitbyToken(store.getters.user.token);
      dataService
        .get<IStrDetailValue[]>(`Service/GetLog/${100}`)
        .then((result) => {
          this.viewmodel = result;
          this.fields = ['base_identifier', 'strvalue', 'verdate'];
        });
    } else if (typ.toLowerCase() == 'report') {
      const { array } = store.state;
      const cols = store.state.columns;
      if (array) {
        this.viewmodel = array;
        this.fields = cols;
        this.fields.push('show_details');
      } else 
         globalInfoService.sendMessage(<IInfo>{
          msg: `Typ nicht gefunden: ${typ}`,
          error: null, type: InfoType.info,
        });{        
      }
    }
  }
}
