
























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  IInfo,
  IDay,
  IWhatsup,
  Iappointment,
  IStrDetailValue,
} from "@/Services/Interfaces";
import store from "../store";
import {
  globalInfoService,
  globalReceiveDetailsService,
  globalWhatsUpService,
  globalAppointmentService,
} from "../Services/GlobalService";
import { dataService } from "../Services/DataServices";
import router from "../router";
import { InfoType } from "../Services/enums";
import * as VirtualList from "vue-virtual-scroll-list";
import Day from "./Day.vue";

@Component({ components: { "virtual-list": VirtualList, Day } })
export default class Calender extends Vue {
  title: string;
  day: string;
  dayinit: string;
  ansicht: string;
  vorzurück: any;
  ansichttoggle: any;
  direction: number;
  private _model: IStrDetailValue;
  

  constructor() {
    super();
    this.name = "days";
    this.model = <IStrDetailValue>{};
    
    this.Days = new Array<IDay>();

    this.title = "";
    this.dayinit = "";
    this.ansicht = "0";
    this.vorzurück = 2;
    this.ansichttoggle = 1;
    this.direction = 0;
    this.day = new Date().toString();
    this._model = <IStrDetailValue>{};
  }

  name: string;
  get model() {
    return this._model;
  }

  set model(value: IStrDetailValue) {
    this._model = value;
  }

  get Days(): IDay[] {
    return store.getters.days;
  }

  set Days(days: IDay[]) {
    store.commit("days", days);
  }

  getgifappswidth(wh: Iappointment): number {
    if (!wh.gif) return 0;
    return wh.gif.toLowerCase().endsWith("png") ||
      wh.gif.toLowerCase().endsWith("gif")
      ? 15
      : 75;
  }

  getgifwidth(wh: IWhatsup): number {
    if (!wh.Gif) return 0;
    return wh.Gif.toLowerCase().endsWith("png") ||
      wh.Gif.toLowerCase().endsWith("gif")
      ? 15
      : 75;
  }
  newTermin(): void {
    router.push("/dyn/0/create/Termine");
  }

  goto(day: string, ansicht: string, direction: number): void {
    router.push("/days/" + this.dayinit + "/" + ansicht + "/" + direction);
  }
  go(step: number): void {
    this.direction = this.direction + step;
    this.goto(this.day, this.ansichttoggle, this.direction);
  }

  edit(e: number): void {
    router.push("/dyn/" + e + "/update/termine");
  }

  getWhatsupClass(item: IWhatsup) {
    var thisclass = "   ";
    if (item.crud == "update") return "alert alert-info";
    return thisclass;
  }

  getAppointmentClass(item: Iappointment) {
    var thisclass = "   ";
    if (item.ergebnis == "abgesagt") return "alert alert-warning";
    if (item.ergebnis == "wunderbar") return "alert alert-primary";
    if (item.crud == "update") return "alert alert-info";
    return thisclass;
  }

  getClass(item: IDay): string {
    var thisclass = " rounded small  ";
    if (item.istoday) return thisclass + " alert alert-success";
    if (item.holiday) return thisclass + " holiday ";
    if (item.weekend) return thisclass + " weekend ";

    return thisclass;
  }

  GetCountItem(): number {
    if (this.ansichttoggle) {
      switch (this.ansichttoggle) {
        case 0:
          return 1;
        case 1:
          return 7;
        case 2:
          return 31;
        case 3:
          return 366;
      }
    }

    return 30;
  }

  created(): void {
    globalWhatsUpService.getMessage().subscribe((ws) => {
      try {
        ws.forEach((w) => {
          this.Days.forEach((day) => {
            var index =
              day.whatsups == undefined
                ? -1
                : day.whatsups.findIndex((g) => g.Id === w.Id);

            if (index > -1) {
              w.crud = "update";
              day.whatsups[index] = w;

              // console.debug("update " + JSON.stringify(w) );
            } else {
              var daydate = new Date(day.date);
              daydate.setHours(0, 0, 0, 0);
              var verdate = new Date(w.Verdate);
              verdate.setHours(0, 0, 0, 0);

              if (verdate == daydate) {
                w.crud = "create";
                if (day.whatsups == undefined) {
                  day.whatsups = new Array<IWhatsup>();
                }
                day.whatsups.push(w);
              }
            }
          });
        });
      } catch (e) {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Aktualisierung geht nicht -> Whatsup..",
          error: e,
        });
      }
    });

    globalAppointmentService.getMessage().subscribe((termine) => {
      try {
        if (!this.Days) return;
        termine.forEach((w) => {
          var day = this.Days.filter((t) => t.date == w.date)[0] as IDay;
          if (!day) return;
          var index = day.appointments.findIndex(
            (g) => g.base_id === w.base_id
          );

          if (index > -1) {
            if (w.crud == "delete") {
              //day.appointments.splice(index, 1);
              Vue.delete(day.appointments, index);
            } else {
              //day.appointments[index] = w;
              Vue.set(day.appointments, index, w);
            }
            // console.debug("update " + JSON.stringify(w) );
          } else {
            if (!w.crud || w.crud == "create") {
              day.appointments.unshift(w);
            }
          }
        });
      } catch (e) {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Termin updaten ging nicht",
          error: e,
        });
      }
    });
  }

  mounted() {
    this.dayinit = this.$route.params.day;
    this.ansicht = this.$route.params.ansicht;
    var direction = Number(this.$route.params.direction);

    this.load(this.dayinit, this.ansicht, direction);
  }

  data() {
    return {
      day: this.dayinit,      
      direction: 0,
      daycomponent: Day,
    };
  }

  load(day: string, ansicht: string, direction: number) {
    if (Number(ansicht) > 1) {
      globalInfoService.sendMessage(<IInfo>{
        type: InfoType.loading,
      });
    }

    dataService.InitbyToken(store.getters.user.token);
    var url = "Service/GetKalender/" + day + "/" + ansicht + "/" + direction;
    dataService.InitbyToken(store.getters.user.token);
    dataService.get<IDay[]>(url).then(
      (g) => {
        globalInfoService.sendMessage(<IInfo>{
          type: InfoType.finished,
        });

        this.Days = g;
        this.direction = direction;
        this.ansichttoggle = ansicht;
        this.$route.params.day = day;
        this.$route.params.ansicht = ansicht;
      },
      (e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Getting details for days",
          error: e,
        });
      }
    );
  }
}
