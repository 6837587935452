


import CryptoJS from 'crypto-js';
import { indedbService } from '../api/indexdbService';
import {
  IUser, IInfo, IStrDetailValue, IWhatsup, IEncrypt, IMedia,
} from './Interfaces';
import BaseService from './BaseService';
import { globalInfoService } from './GlobalService';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';


export interface IDataServices {
  InitUser(): Promise<any>;
  Init(email: string, psw: string): void
  InitbyToken(token: string): void
  get(url: string): Promise<any>
  post<T>(url: string, obj: T): Promise<any>
}

export default class DataServices extends BaseService implements IDataServices {
  _token = 'unbekannt';

  gettoken(): string {
    return this._token;
  }

  CreateConnection(token: string): HubConnection {
    this._token = token;
    if (this.gettoken() == undefined) {
      console.debug('Token unbekannt hub starten geht nicht ');
    }
    const url = (process.env.NODE_ENV === 'production'
      ? '/core/stefshub'
      : '/stefshub'
    );
    return new HubConnectionBuilder()
      .withUrl(`${url}?Token=${token}`, { accessTokenFactory: () => this.gettoken() })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();
  }

  baseurl?: string = '';

  email = '';

  psw = '';


  InitbyToken(token: string): void {
    this.headers = new Headers();
    this.headers = new Headers();
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Content-Type', 'application/json, application/x-www-form-urlencoded');
    this.headers.append('Access-Control-Allow-Methods', 'DELETE, POST, GET');
    this.headers.append('Access-Control-Request-Headers', 'token');
    this.headers.append('Access-Control-Request-Headers', 'access_token');
    this.headers.append('Access-Control-Allow-Headers', 'Access-Control-Request-Headers, token, Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'),
      this.headers.append('token', token);
  }


  headers = new Headers();

  constructor() {
    super();
    this.baseurl = process.env.VUE_APP_BASE_URL;
    console.debug("Vue Baseurl " + process.env.BASE_URL);
    console.debug("Baseurl " + this.baseurl);
  }


  /** gleiches Verschlüsselungsverfahrenwie auf Server  */
  Encrypt(decpryptkey: string, strvalue: string): string {
    try {
      const cipherText = CryptoJS.AES.encrypt(
        strvalue,
        decpryptkey,
      ).toString();

      return cipherText;
    } catch (e) {
      globalInfoService.sendMessage(<IInfo>{
        msg: 'Encrypion Fehler...',
        error: e,
      });
      return '';
    }
  }


  /** Holt den Serverschlüssel */
  public GetEncryptCode(baseid: number): Promise<IEncrypt> {
    return new Promise<IEncrypt>((resolve, reject) => {
      indedbService.db().then((d) => {
        d.get('Kennung', baseid).then((key) => {
          if (key == undefined) {
            return reject(new Error(' Key nicht gefunden. Check Login ?! '));
          }
          
          return resolve(key);
        }).catch(e => reject(e));
      }, e => reject(e));
    });
  }


  public InitUser(): Promise<IUser> {
    const index = indedbService.db().then((d) => {
      d.getAll('Login').then((users) => {
        users.forEach(user => user);
      }).then((data) => {
        console.info("user found");
      }).catch((e) => {
        d.deleteObjectStore('Login');
        return Promise.reject('error login db');
      });
     
    }).catch(e => Promise.reject('error login db'));

    return Promise.reject();
  }

  public Init(email: string, psw: string): void {
    this.email = email;
    this.psw = psw;
    this.headers = new Headers();


    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Content-Type', 'application/json, application/x-www-form-urlencoded');
    this.headers.append('Access-Control-Allow-Methods', 'DELETE, POST, GET');
    this.headers.append('Access-Control-Request-Headers', 'email, psw');
    // this.headers.append("Access-Control-Allow-Headers", "Access-Control-Request-Headers, email, psw, Content-Type, Access-Control-Allow-Headers, Authorization"),

    this.headers.append('application', 'Adressen');
    this.headers.append('email', this.email);
    this.headers.append('psw', this.psw);
  }

  public post<T, R>(url: string, obj: T): Promise<R> {
    const header = this.headers;

    //  url = "http://stefhorst.de/wcf/WcfRestService.svc/Link/vue";
    return fetch(this.baseurl + url,
      {
        method: 'POST',
        body: JSON.stringify(obj),
        mode: 'cors',
        headers: header,
      }).then((response) => {
        if (!response.ok) {
          return Promise.reject(response.bodyUsed ? response.json() : response);
        }
        return response.json();
      }).then((e) => {
        if (e.Exception) {
          return Promise.reject(e);
        }
        return e;
      });
  }


  public sendformData<R>(url: string, obj: any,  _headers: Headers): Promise<R> {
    

    return fetch(this.baseurl + url,
      {
        method: 'POST',
        body: obj,
        mode: 'cors',
        headers: _headers,
      }).then((response) => {
        if (!response.ok) {
          return Promise.reject(response.bodyUsed ? response.json() : response);
        }
        return response.json();
      }).then((e) => {
        if (e.Exception) {
          return Promise.reject(e);
        }
        return e;
      });
  }

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Preflighted_requests
  public get<T>(url: string): Promise<T> {
    const header = this.headers;
    var url = this.baseurl + url;

    return fetch(url,
      {
        method: 'GET',
        mode: 'cors',
        // redirect: 'follow',
        // referrer: 'no-referrer', // no-referrer, *client
        headers: header,
      }).then((response) => {
        if (!response.ok) {
          return Promise.reject(response.bodyUsed ? response.json() : response);
        }
        return response.json();
      }).then((e) => {
        if (e.Exception) {
          return Promise.reject(e);
        }
        return e;
      });
  }
}


export const dataService = new DataServices();
