<template>
  <div :id="id" class="swiper-container">
    <div class="swiper-wrapper">
      <template>
        <div :min-height="tileheightLeft" v-bind:class="tabclass">
          <v-container fluid class="slideaway">
            <v-row>
              <v-col>
                {{ getAwayText() }}
              </v-col>
              <v-col v-if="isTermin()">
                <v-btn-toggle shaped dense>
                  <v-btn small :disabled="terminvor < 1" @click="terminvor--">
                    <v-icon> arrow_left</v-icon>
                  </v-btn>
                  <v-btn small>{{ getDate(terminvor) }}</v-btn>
                  <v-btn small @click="terminvor++">
                    <v-icon> arrow_right</v-icon>
                  </v-btn>
                  <v-btn
                    title="OK, verschieben"
                    small
                    @click="terminvorschieben()"
                  >
                    <v-icon color="#605d92"> checked</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-bind:class="tabclass">
          <slot ref="tileboxLeft"></slot>
        </div>
        <div :min-height="tileheightLeft" v-bind:class="tabclass">
          <v-container fluid class="slideawayleft">
            <v-row>
              <v-col v-if="isTermin()">
                <v-btn-toggle shaped dense>
                  <v-btn small @click="terminzurück++">
                    <v-icon> arrow_left</v-icon>
                  </v-btn>
                  <v-btn small>{{ getDate(terminzurück * -1) }}</v-btn>
                  <v-btn
                    small
                    :disabled="terminzurück < 1"
                    @click="terminzurück--"
                  >
                    <v-icon> arrow_right</v-icon>
                  </v-btn>
                  <v-btn
                    title="Ok, in die Vergangenheit"
                    small
                    @click="terminzurückschieben()"
                  >
                    <v-icon color="#605d92"> checked</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col>
                {{ getOkText() }}
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Swiper } from "swiper/swiper.cjs";

export default {
  props: {
    id: [String],
    item: {},
  },

  computed: {
  
  },

  methods: {
    terminzurückschieben() {
      this.$emit(
        "transitionOK",
        this.thisitem,
        this.swiper.activeIndex,
        Number(this.terminzurück) * -1
      );
    },
    terminvorschieben() {
      this.$emit(
        "transitionOK",
        this.thisitem,
        this.swiper.activeIndex,
        this.terminvor
      );
    },
    isTermin() {
      if (!this.thisitem) return false;
      return (
        this.thisitem.hasOwnProperty("zeit") &&
        this.thisitem.hasOwnProperty("date")
      );
    },
    getOkText() {
      try {
        if (!this.thisitem) return "";
        var item = this.thisitem;
        if (item && item.hasOwnProperty("Typ")) {
          if (item.Typ.toLowerCase() == "adresse") return "da geht nix...";
          if (item.Typ.toLowerCase() == "topnews") return "...zur Seite";
          // if (item.Typ.toLowerCase() == "whatsup") return "alles gut";
        } else if (item.hasOwnProperty("zeit") && item.hasOwnProperty("date")) {
          return new Date(item["date"]).toLocaleDateString();
        }
        return "alles gut...";
      } catch (e) {
        console.debug(e);
        return "... unbekannt";
      }
    },
    getDate(val) {
      var nextday = new Date(this.thisitem.date);
      nextday.setDate(nextday.getDate() + val);
      return nextday.toLocaleDateString();
    },
    getAwayText() {
      try {
        if (!this.thisitem) return "";
        var item = this.thisitem;
        if (item && item.hasOwnProperty("Typ")) {
          if (item.Typ.toLowerCase() == "adresse")
            return "da geht nicht viel...";
          if (item.Typ.toLowerCase() == "whatsup")
            return "Als gelesen markieren";
          if (item.Typ.toLowerCase() == "topnews") return "...zur Seite";
        } else if (item.hasOwnProperty("zeit") && item.hasOwnProperty("date")) {
          return new Date(item["date"]).toLocaleDateString();
        }
        return "... weg";
      } catch (e) {
        console.debug(e);
        return "... unbekannt";
      }
    },
  },
  data() {
    return {
      tabclass: "swiper-slide tapenter",
      tileheight: "100%",
      tileheightLeft: "100%",
      side: 1,
      thisitem: this.item,
      terminzurück: 1,
      terminvor: 1,
      swiper: {},
    };
  },
  mounted() {
    const self = this;
    const el = `#${this.id}`;

    const swiper = new Swiper(el, {
      initialSlide: this.side,
      freeModeMomentumBounceRatio: 3,
      resistanceRatio: 0,
      effect: "flip",
      shortSwipes: false,
    });
    (this.swiper = swiper),
      swiper.on("touchStart", function () {
        this.tabclass = " swiper-slide tapenter";
      });

    swiper.on("touchEnd", function () {
      this.tabclass = "swiper-slide  ";
    });

    // Event will be fired after transition
    swiper.on("slideChangeTransitionEnd", function () {
      if (swiper.realIndex != 1) {
        self.$emit("transitionEnd", self.item, swiper.realIndex);
        this.side = 1; // back
        swiper.activeIndex = 1;
        // this.destroy();
      }
    });
  },
};
</script>

<style>
.tapenter {
  border-radius: 4px;
  background: white;
}
.tapenter:hover {
  background: #efeef7;
}
.slideaway {
  background: rgb(241, 235, 226);
  color: black;
  text-align: center;
  align-items: center;
}

.slideawayleft {
  background: rgb(172, 215, 233);
  color: black;
  text-align: center;
  align-items: center;
}
</style>
