








































































































































































































































































































import Vue from "vue";

import {
  IUser,
  IWhatsup,
  IInfo,
  IKurs,
  IBaseResult,
  Iappointment,
  ITopNew,
  IKursPrediction,
  IEntwicklung,
} from "../Services/Interfaces";
import { dataService } from "../Services/DataServices";

import {
  globalWhatsUpService,
  globalUserService,
  globalInfoService,
  globalTopNewsReceiveService,
  globalKurseUpService,
  globalAppointmentService,
  globalKursePredictionService,
} from "../Services/GlobalService";



import router from "../router";
import store from "../store";
import { InfoType, KursType, KursTypObject } from "../Services/enums";
import { loginViewModel } from "../Services/LoginViewModel";
import SwipeoutItem from "./Base/SwipeoutItem.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    SwipeoutItem,
    Message: () => import("./Message.vue"),
  },
})
export default class Whatsup extends Vue {
  kurstypen = new Array<KursTypObject>();

  canNew: boolean;

  toggledwhatsup: IWhatsup[];
  user: IUser = <IUser>{};
  kursetoggled = new Array<KursTypObject>();

  topnews: Array<IWhatsup>;
  blankWhatsup = <IWhatsup>{};
  msg: string;
  iconWidth = 25;
  imageWidth = 50;

  kursprediction: IKursPrediction[];

  constructor() {
    super();

    this.topnews = new Array<ITopNew>();

    this.kursprediction = new Array<IKursPrediction>();
    this.msg = "";

    this.canNew = false;

    this.toggledwhatsup = new Array<IWhatsup>();
  }

  get termine() {
    return store.getters.termine;
  }

  set termine(arr: Array<Iappointment>) {
    store.commit("termine", arr);
  }

  get viewmodel() {
    return store.getters.whatsups;
  }

  set viewmodel(arr: Array<IWhatsup>) {
    store.commit("whatsups", arr);
  }

  formatDateToTime(date: Date): string {
    try {
      date = new Date(date);
      var d =
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0");
      return d;
    } catch (e) {
      return "";
    }
  }

  whatsupClass(item: any): string {
    if (!item) return "";
    if (item.crud == "update") return "alert alert-info";
    if (item.crud == "create") return "alert alert-success";
    if (item.crud == "delete") return "alert alert-warning";
    return "";
  }

  canAction(item: any): boolean {
    return (
      (item.hasOwnProperty("Typ") &&
        item.Typ &&
        item.Typ.toLowerCase() == "whatsup") ||
      item.Typ.toLowerCase() == "mail"
    );
  }

  transitionOK(item: any, side: number, val: any): void {
    if (item.hasOwnProperty("zeit") && item.hasOwnProperty("date")) {
      if (side != 0 /* weg */) {
        var nextday = new Date(item.date);
        nextday.setDate(nextday.getDate() + Number(val));
        globalInfoService.sendMessage(<IInfo>{
          msg:
            item.titel +
            " wurde auf den " +
            nextday.toLocaleDateString() +
            " gelegt....",
          type: InfoType.warning,
        });
        dataService.InitbyToken(this.user.token);
        dataService
          .get<IBaseResult>(
            "Service/UpdateFieldValuebyBaseId/" +
              item.base_id +
              "/Datum/" +
              nextday.toLocaleDateString("de-DE")
          )
          .then((d) => {
            if (d.error) {
              globalInfoService.sendMessage(<IInfo>{
                msg: item.Titel + " konnte nicht aktualisiert werden",
                error: d.error,
                type: InfoType.warning,
              });
            }
            var index = this.termine.findIndex(
              (t) => t.base_id == item.base_id
            );

            var bis = this.termine[index].bis;
            if (bis != undefined) {
              var nextbis = new Date(item.bis);

              nextbis.setDate(nextbis.getDate() + Number(val));
              dataService
                .get<IBaseResult>(
                  "Service/UpdateFieldValuebyBaseId/" +
                    item.base_id +
                    "/BisDatum/" +
                    nextbis.toLocaleDateString("de-DE")
                )
                .then((er) => {
                  //ok bis auch geändert
                  if (er.error) {
                    console.debug(er.error);
                  }
                });
            }

            if (index > -1) 
            {
            Vue.delete(this.termine, index);
            }
          });
      }
    }
  }

  transitionEnd(item: any, side: number): void {
    try {
      this.user = this.user || store.getters.user;
      if (
        (item &&
          item.Typ != undefined &&
          item.Typ.toLowerCase() == "whatsup") ||
        item.Typ.toLowerCase() == "mail"
      ) {
        if (side == 0) {
          // wurde ganz nach rechts
          dataService.InitbyToken(this.user.token);
          dataService
            .get<IBaseResult>(
              "Service/UpdateFieldValue/" + item.Id + "/Read/-1"
            )
            .then((d) => {
              if (d.error) {
                globalInfoService.sendMessage(<IInfo>{
                  msg: item.Titel + " konnte nicht deaktiviert werden",
                  error: d.error,
                  type: InfoType.warning,
                });
              }
              var index = this.viewmodel.findIndex((t) => t.Id == item.Id);
              if (index > -1) 
            {
              Vue.delete(this.viewmodel, index);
            }
            });
        } else if (side == 2) {
          // nach links
          var index = this.viewmodel.findIndex((t) => t.Id == item.Id);
          if (index > -1) 
            {
          Vue.delete(this.viewmodel, index);
            }
        }
      } else {
        if (
          item.Typ &&
          (item.Typ.toLowerCase() == "topnews" ||
            item.Typ.toLowerCase() == "kursinfo")
        ) {
          var index = this.topnews.findIndex((t) => t.Id == item.Id && item.crud != 'persist');
          if (index > -1) 
          {
            Vue.delete(this.topnews, index);
          }
        } else if (item.Typ && item.Typ.toLowerCase() == "adresse") {
          var index = this.viewmodel.findIndex((t) => t.Id == item.Id);
          if (index > -1) 
          {
            Vue.delete(this.viewmodel, index);
          }
        } else if (
          item.Typ &&
          item.Typ.toLowerCase() == "kurse" &&
          store.getters.kurse
        ) {
          var k = store.getters.kurse as IKurs[];
          var index = k.findIndex((t: IKurs) => t.base_id == item.Id);
          if (index > -1) 
          {
          Vue.delete(k, index);
          }
          store.dispatch("kurse", k);
        } else {
          var index = this.viewmodel.findIndex((t) => t.Id == item.Id);
          if (index > -1) {
            Vue.delete(this.viewmodel, index);
          }
        }
        // weg damit
      }
    } catch (e) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Transition fails",
        error: e,
        type: InfoType.debug,
      });
    }
  }

  /**Kann eine Message geschrieben ider beantworet werden */
  canMessage(item: IWhatsup): boolean {
    return (
      item.crud != "create" &&
      item.Typ != undefined &&
      (item.Typ.toLowerCase() == "whatsup" || item.Typ.toLowerCase() == "mail")
    );
  }

  togglekurse(kurstype: KursTypObject): void {
    if (!this.kursetoggled) return;
    var id = this.kursetoggled.findIndex(
      (t) => t.KursType == kurstype.KursType
    );
    if (id == -1) {
      this.kursetoggled.push(kurstype);
    } else {
      Vue.delete(this.kursetoggled, id);
    }
  }

  toggle(item: any) {
    if (item == undefined) return;
    var index = this.toggledwhatsup.findIndex((t) => t.Id == item.Id);
    if (index > -1) {
      Vue.delete(this.toggledwhatsup, index);
    } else {
      this.toggledwhatsup.push(item);
    }
  }

  goto(url: string): void {
    window.open(url, "_blank",'noreferrer');
  }

  toggledKurs(item: KursTypObject): boolean {
    if (item == undefined) return false;
    if (this.kursetoggled == undefined) return false;
    var val = this.kursetoggled.findIndex((t) => t.KursType === item.KursType);
    return val > -1;
  }

  toggled(item: IWhatsup): boolean {
    if (item == undefined) return false;

    if (this.toggledwhatsup == undefined) return false;
    var val = this.toggledwhatsup.findIndex((t) => t.Id === item.Id);
    return val > -1;
  }

  canAntwort(item: IWhatsup): boolean {
    if (item == undefined) return false;

    if (item.Typ && item.Typ.toLowerCase() == "kurse") return false;
    if (item.Typ && item.Typ.toLowerCase() == "termine") return false;
    return item.crud != "delete";
  }

  GetPositivDiffStatus(item: IKurs): any {
    if (item.Wert_zu_Einstieg <= 1 || item.Wert_zu_Einstieg == 0) {
      return null;
    }
    return Math.abs(1 - Math.abs(item.Wert_zu_Einstieg)) * 100;
  }

  GetNegativDiffStatus(item: IKurs): any {
    if (item.Wert_zu_Einstieg > 1 || item.Wert_zu_Einstieg == 0) {
      return null;
    }
    return Math.abs(1 - Math.abs(item.Wert_zu_Einstieg)) * 100;
  }

  showEntwicklung(kurstype: KursTypObject): boolean {
    return kurstype.KursType == KursType.Kurse;
  }

  getGuvs(kurstype: KursTypObject): IKurs[] {
    if (!store.getters.kurse) return new Array<IKurs>();
    var k = store.getters.kurse as IKurs[];
    var kurse = k.filter((z: IKurs) => z.KursType == kurstype.KursType);

    return kurse;
  }

  edit(baseid: number): void {
    router.push("/dyn/" + baseid + "/update/record");
  }

  getTimeClass(item: Iappointment): string {
    try {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var todaytimevor15min = new Date();
      todaytimevor15min.setMinutes(todaytimevor15min.getMinutes() - 15);
      var todaytimenach15min = new Date();
      todaytimenach15min.setMinutes(todaytimenach15min.getMinutes() + 15);

      var datatoday = new Date(item.date.substring(0, 10));

      datatoday.setHours(0, 0, 0, 0);
      if (
        datatoday.getTime() == today.getTime() &&
        item.zeit &&
        item.zeit.indexOf(":") > -1
      ) {
        var datatime = new Date();
        datatime.setMinutes(Number(item.zeit.substr(3, 2)));
        datatime.setHours(Number(item.zeit.substr(0, 2)));
        if (
          datatime.getTime() > todaytimevor15min.getTime() &&
          todaytimenach15min.getTime() > datatime.getTime()
        ) {
          return "alert alert-success ";
        }
      }
    } catch (e) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Datetime Fehler " + item.titel,
        error: e,
        type: InfoType.debug,
      });
    }
    return "";
  }
  termineClass(item: Iappointment): string {
    if (!item) return "";
    if (item.crud == "update") return "alert alert-info";
    if (item.crud == "create") return "alert alert-success";
    if (item.crud == "delete") return "alert alert-warning";
    if (item.ergebnis == "abgesagt") return "alert alert-warning";
    if (item.ergebnis == "wunderbar") return "alert alert-primary";
    return this.getTimeClass(item);
  }

  classTopnews(item: IWhatsup): string {
    if (!item) return "";
    if (item.crud == "update") return "alert alert-info";
    if (item.crud == "create") return "alert alert-primary";
    return "";
  }

  textwrapStyle(): string {
    return "word-break: break-word;";
  }
  newWhatsup(): void {
    this.canNew = !this.canNew;
  }

  created(): void {
    globalTopNewsReceiveService.getMessage().subscribe((w) => {
      try {
        if (!this.topnews) {
          this.topnews = new Array<IWhatsup>();
        }
        var index = this.topnews.findIndex((g) => g.Id == w.Id);
        if (index > -1) {
          w.crud = "update";
          Vue.set(this.topnews, index, w);
        } else {
          this.topnews.unshift(w);
        }
        store.dispatch("topnews", this.topnews);
      } catch (e) {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Es gibt einen Aktualisierungsfehler TopNews ",
          error: e,
        });
      }
    });

    globalUserService.getMessage().subscribe((user) => {
      //  console.debug("user" + JSON.stringify(user));
      this.blankWhatsup.To = user.email;
      globalInfoService.sendMessage(<IInfo>{
        msg: "",
        error: null,
        type: InfoType.loading,
      });
      if (user.token) {
        dataService.InitbyToken(user.token);
        dataService
          .get<IWhatsup[]>("Service/GetWhatsupsObjects")
          .then((d) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "",
              error: null,
              type: InfoType.finished,
            });
            this.viewmodel = d;
            store.dispatch("whatsups", d);
          })
          .catch((ex) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Es gibt einen whatsup Fehler",
              error: ex,
            });
          });
      }
    });

    globalKursePredictionService.getMessage().subscribe((kursprediction) => {
      try {
        store.dispatch("kursprediction", kursprediction);
        // this.setKursprediction(kursprediction);
      } catch (e) {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Es gibt einen Aktualisierungsfehler ->  KI",
          error: e,
        });
      }
    });

    globalKurseUpService.getMessage().subscribe((kurse) => {
      try {
        if (kurse.length == 0) return;
        var kurstypen = new Array<KursTypObject>();
        kurse.forEach((r) => {
          if (kurstypen.filter((t) => t.KursType == r.KursType).length == 0) {
            kurstypen.push(<KursTypObject>{
              KursType: r.KursType,
              Typ: KursType[r.KursType],
              Name: KursType[r.KursType],
              Id: Number(r.KursType),
            });
          }
        });
        store.dispatch("kurstypen", kurstypen);
        this.kurstypen = kurstypen;
        var k = store.getters.kurse as IKurs[];

        kurse.forEach((r) => {
          var index = k.findIndex((g: IKurs) => g.base_id === r.base_id);
          if (index > -1) {
            Vue.set(k, index, r);
          } else {
            k.unshift(r);
          }
        });
        store.dispatch("kurse", kurse);
      } catch (e) {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Es gibt einen Aktualisierungsfehler ->  Kurse",
          error: e,
        });
      }
    });

    globalAppointmentService.getMessage().subscribe((termine) => {
      try {
        var oldtermine = this.termine as Iappointment[];
        oldtermine.forEach((element) => {
          if (element.expires && new Date(element.expires) < new Date()) {
            var index = oldtermine.findIndex(
              (t) => t.base_id === element.base_id
            );
            if (index > -1) {
              element.crud = "delete";
              Vue.set(oldtermine, index, element);
            }
          }
        });

        this.termine.forEach((w) => {
          var index = this.termine.findIndex((g) => g.base_id === w.base_id);
          if (index > -1) {
            if (w.crud == "delete") {
              Vue.delete(this.termine, index);
            } else {
              Vue.set(this.termine, index, w);
            }
          } else {
            this.termine.unshift(w);
          }
        });
        store.dispatch("termine", termine);
      } catch (e) {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Termin updaten ging nicht",
          error: e,
        });
      }
    });

    globalWhatsUpService.getMessage().subscribe((ws) => {
      try {
        if (ws == undefined) return;

        ws.forEach((w) => {
          var index = this.viewmodel.findIndex((g) => g.Id == w.Id);

          if (index > -1) {
            if (w.crud === "delete") {
              Vue.delete(this.viewmodel, index);
            } else if (w.crud === "truncate") {
              Vue.delete(this.viewmodel, index);
            } else {
              Vue.set(this.viewmodel, index, w);
            }
          } else {
            w.crud = "create";
            this.viewmodel.unshift(w);
            this.canNew = false;
          }
        });

        store.dispatch("whatsups", this.viewmodel);
      } catch (e) {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Es gibt einen Aktualisierungsfehler whatsup Fehler",
          error: e,
        });
      }
    });
  }

  _today = new Date();
  mounted(): void {
    msg: "Whatsups";

    loginViewModel.GetUser().then((user) => {
      this.user = user;
      if (loginViewModel.hasSignalConnection == true) {
        console.log("refresh");
        loginViewModel.refreshonServer(this.user).then((g) => {
          if (g.error && g.error.length > 0) {
            this.reload(this.user);
          }
        });
      } else {
        this.reload(this.user);
      }
    });
  }

  reload(user: IUser): void {
    dataService.InitbyToken(user.token);
    globalInfoService.sendMessage(<IInfo>{
      msg: "",
      error: null,
      type: InfoType.loading,
    });

    dataService
      .get<IWhatsup[]>("Service/GetWhatsupsObjects")
      .then((d) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "",
          error: null,
          type: InfoType.finished,
        });
        this.viewmodel = d;
        store.dispatch("whatsups", d);
      })
      .catch((e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Get whatsup error",
          error: e,
        });
      });

    dataService
      .get<Iappointment[]>("Service/GetTermine")
      .then((d) => {
        this.termine = d;
        store.dispatch("termine", d);
        globalInfoService.sendMessage(<IInfo>{
          msg: "",
          error: null,
          type: InfoType.finished,
        });
      })
      .catch((e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Get Termine error",
          error: e,
        });
      });
  }

  data() {
    return {
      msg: "Whatsup",
      canNew: false,
      kursprediction: store.getters.kursprediction,
      kurstypen: store.getters.kurstypen,
      topnews: store.getters.topnews,
      toggledwhatsup: new Array<IWhatsup>(),
      kursetoggled: [],
      blankWhatsup: <IWhatsup>{},
      user: store.getters.user,
    };
  }
}
