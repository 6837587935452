































































































import { Component, Vue } from "vue-property-decorator";
import { dataService } from "../Services/DataServices";
import store from "../store";
import {
  IStrDetailValue,
  IInfo,
  IMedia,
  IParentValue,
  IBaseResult,
  IUpdateValueModel,
  ICryptData,
} from "../Services/Interfaces";
import {
  globalInfoActionService,
  globalInfoService,
  globalUpdateDetailService,
  globalUpdateMediaService,
} from "../Services/GlobalService";


import DeleteButton from "./Controls/DeleteButton.vue";
import { CrudType, InfoType } from "../Services/enums";
import { loginViewModel } from "../Services/LoginViewModel";


@Component({
  components: {
    RelationComponent: () => import("./Base/RelationComponent.vue"),
    TextComponent: () => import("./Base/TextComponent.vue"),
    ActionComponent: () => import("./Base/ActionComponent.vue"),    
    IFrameComponent: () => import("./Base/IFrameComponent.vue"),
    CheckBoxComponent: () => import("./Base/CheckBoxComponent.vue"),
    MemoComponent: () => import("./Base/MemoComponent.vue"),
    EmailComponent: () => import("./Base/EmailComponent.vue"),
    DateComponent: () => import("./Base/DateComponent.vue"),
    Feeds: () => import("./Feeds.vue"),    
    URLComponent: () => import("./Base/UriComponent.vue"),
    PictureComponent: () => import("./Base/PictureComponent.vue"),
    AutoCompleteComponent: () => import("./Base/AutoCompleteComponent.vue"),
    SelectComponent: () => import("./Base/SelectComponent.vue"),
    DeleteButton,    
    MovieComponent: () => import("./Base/MovieComponent.vue"),
    AudioComponent: () => import("./Base/AudioComponent.vue"),
    AskaQuestionComponent: () => import("./Base/AskaQuestionComponent.vue"),
  },
})
export default class DynComponent extends Vue {
  fields = new Array<IStrDetailValue>();

  typ: string;
  felder: string[];
  kategorien: string[];
  kategorie: string;
  medias: IMedia[];
  modus: string;
  value_id: string | undefined;
  basemodel: IStrDetailValue = <IStrDetailValue>{};
  childRefs: any = [];
  

  constructor() {
    super();
    this.name = "Dyncomponent";
    this.title = "";
    this.baseid = -1;
    this.fields = new Array<IStrDetailValue>();
    this.medias = new Array<IMedia>();
    this.fieldnames = [];
    this.felder = new Array<string>();
    this.kategorien = new Array<string>();
    this.fieldname = "";
    this.kategorie = "";
    this.typ = "";
    this.modus = "noedit";
    this.cansave = true;
  }

  baseid: number;
  name: string;
  title: string;
  fieldnames: string[];
  fieldname: string;

  selectKategorie(): void {}

  getClassName(): string {
    return "first";
  }

  addRefs(ref: any): void {
    if (ref != undefined) {
      this.childRefs.push(ref);
    }
  }
  canKategorien(): boolean {
    return (
      this.kategorien && this.kategorien.length > 1 && this.modus != "noedit"
    );
  }

  canField(): boolean {
    if (!this.fields || this.fields.length == 0) return false;
    return this.felder && this.felder.length > 0 && this.modus != "noedit";
  }

  canDelete(): boolean {
    return this.modus == "update" || this.modus == "edit";
  }

  


  cansave: boolean;

  canSave(): boolean {
    return this.cansave == true;
  }

  save(): void {
    this.cansave = false;
    dataService.InitbyToken(store.getters.user.token);
    var urlfieldnames = "Service/InsertValues";
    this.fields.forEach((f) => {
      f.typname = this.typ;
      f.error = ""; // zurück setzn
    });

    if (Number(this.value_id) > 0) {
      var first = this.fields[0];
      first.parent = <IParentValue>{
        base_id: this.baseid,
        value_id: Number(this.value_id),
      };
    }

    dataService.post<any, IStrDetailValue[]>(urlfieldnames, this.fields).then(
      (g) => {
        this.cansave = true;
        if (g.filter((t) => t.error && t.error.length != 0).length == 0) {
          globalInfoService.sendMessage(<IInfo>{
            msg: "OK...",
            type: InfoType.info,
            error: null,
          });
          this.modus = "update";
          this.baseid = g[0].base_id;
          this.load("");
        } else {
          this.fields.forEach((f) => {
            if (
              g.filter(
                (t) =>
                  t.fieldname == f.fieldname && t.error && t.error.length > 0
              ).length > 0
            ) {
              f.error = g.filter(
                (t) => t.fieldname == f.fieldname && t.error != undefined
              )[0].error;
              globalInfoService.sendMessage(<IInfo>(<unknown>{
                msg: "Speicherproblem",
                type: InfoType.warning,
                error: "Leider ein Fehler ...." + f.error,
              }));
            }
          });
        }
      },
      (e) => {
        this.cansave = true;
        globalInfoService.sendMessage(<IInfo>{
          msg: "Sichern ging schief...",
          error: e,
        });
      }
    );
  }

  newField(fieldname: any) {
    dataService.InitbyToken(store.getters.user.token);
    var urlfieldnames = "Service/NewField/" + this.baseid + "/" + fieldname;
    dataService.get<any>(urlfieldnames).then(
      () => {
        this.load(fieldname);
      },
      (e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Felder laden ging schief...",
          error: e,
        });
      }
    );
  }

  getComponentType(data: IStrDetailValue): string {
    if (data.valuetyp.toLowerCase() == "relation") {
      return "RelationComponent";
     } else if (data.valuetyp.toLowerCase() == "audio") {
      return "AudioComponent";    
    } else if (data.valuetyp.toLowerCase() == "movie") {
      return "MovieComponent";
    } else if (data.inputtype.toLowerCase() == "autocomplete") {
      return "AutoCompleteComponent";
    } else if (
      data.valuetyp.toLowerCase() === "checkbox" ||
      (data.inputtype.toLowerCase() != "email" &&
        data.listvalues &&
        data.listvalues.length > 0)
    ) {
      return "CheckBoxComponent";
    } else if (
      data.valuetyp.toLowerCase() === "link" &&
      data.memovalue.indexOf("iframe") > -1
    ) {
      if (data.crud.toLowerCase() == "update") {
        return "URLComponent";
      } else {
        return "IFrameComponent";
      }
    } else if (data.valuetyp.toLowerCase() === "date") {
      return "DateComponent";
    } else if (data.valuetyp.toLowerCase() === "picture") {
      return "PictureComponent";
    } else if (data.valuetyp.toLowerCase() === "link") {
      return "URLComponent";
    } else if (data.valuetyp.toLowerCase() === "questionanswer") {
      return "AskaQuestionComponent";
    } else if (
      data.valuetyp.toLowerCase() === "string" &&
      data.inputtype.toLowerCase() === "textarea"
    ) {
      return "MemoComponent";
    } else if (data.valuetyp.toLowerCase() === "feed") {
      return data.crud == "update" ? "MemoComponent" : "Feeds";
    } else if (data.inputtype.toLowerCase() === "select") {
      return "SelectComponent";
    } else if (
      (data.valuetyp.toLowerCase() === "string" &&
        data.inputtype.toLowerCase() === "email") ||
      data.fieldname.toLowerCase() == "to" ||
      data.fieldname.toLowerCase() == "from"
    ) {
      return "EmailComponent";
    }

    return "TextComponent";
  }


  mounted() {
    this.baseid = Number(this.$route.params.base_id);
    this.modus = this.$route.params.modus;
    this.typ = this.$route.params.typ;
    this.value_id = this.$route.params.value_id;
    loginViewModel.GetUser().then(() => {
      this.load("");
    });
  }

  created(): void {
    globalUpdateMediaService.getMessage().subscribe((update) => {
      if (update.typ == "löschen" || update.typ == "delete") {
        this.loadMedias();
      }
    });
    globalUpdateDetailService.getMessage().subscribe((update) => {
      if (update.crud.toLowerCase() == CrudType.deletechildbaseid) {
        dataService.InitbyToken(store.getters.user.token);
        dataService.InitbyToken(store.getters.user.token);
        dataService
          .get<IBaseResult>("Service/DeleteBaseValue/" + update.child_base_id)
          .then((r) => {
            update.error = r.error;
            globalInfoService.sendMessage(<IInfo>{
              id: update.id,
              msg: "Relation komplett gelöscht..",
              type: InfoType.warning,
              error: r.message,
              loading: false,
            });
          })
          .catch((e) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Datensatz konnte nicht gelöscht werden ... ",
              error: e,
            });
          });
      } else if (update.crud.toLowerCase() == CrudType.updatechildbaseid) {
        if (update.id < 1) return;
        dataService.InitbyToken(store.getters.user.token);
        dataService
          .get<IBaseResult>(
            "Service/UpdateChildRelation/" +
              update.id +
              "/" +
              +update.child_base_id +
              "/" +
              update.strvalue
          )
          .then((r) => {
            // ok
          })
          .catch((e) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Verknüpfung konnte nicht gesetzt werden ... ",
              error: e,
            });
          });
      } else if (update.crud.toLowerCase() == CrudType.deletebase) {
        dataService.InitbyToken(store.getters.user.token);
        dataService
          .get<IBaseResult>("Service/DeleteBaseValue/" + update.base_id)
          .then((r) => {
            update.error = r.error;
            globalInfoService.sendMessage(<IInfo>{
              id: update.id,
              msg: "Komplett gelöscht..",
              type: InfoType.warning,
              error: r.message,
              loading: false,
            });
          })
          .catch((e) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Datensatz konnte nicht gelöscht werden ... ",
              error: e,
            });
          });
      } else if (update.crud.toLowerCase() == CrudType.deletevalue) {
        if (update.id < 1) return;

        dataService.InitbyToken(store.getters.user.token);
        dataService
          .get<IBaseResult>("Service/DeleteValue/" + update.id)
          .then((r) => {
            update.error = r.error;
            globalInfoActionService.sendMessage(<IInfo>{
              id: update.id,
              msg: "Löschen..",
              type: InfoType.warning,
              error: r.message,
              loading: false,
            });
          })
          .catch((e) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Nicht gelöscht ... ",
              error: e,
            });
          });
      } else if (update.crud.toLowerCase() == CrudType.updatememovalue) {
        if (update.id < 1) return;
        dataService.InitbyToken(store.getters.user.token);
        dataService
          .post<any, IUpdateValueModel>("Service/UpdateMemoValue", <
            IUpdateValueModel
          >{
            value_id: update.id,
            txtvalue: update.memovalue,
          })
          .catch((e) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Could not update memo,... ",
              error: e,
            });
          });
      } else if (
        update.valuetyp.toLowerCase() == "secure" &&
        update.crud.toLowerCase() == CrudType.updatestrvalue
      ) {
        var token = store.getters.user.token;
        var user = store.getters.user;
        dataService
          .GetEncryptCode(user.base_id)
          .then((code) => {
            dataService.InitbyToken(store.getters.user.token);
            var crypt = dataService.Encrypt(code.key, update.strvalue);
            var ec = <ICryptData>{
              id: update.id,
              encryptedtext: crypt,
            };
            dataService
              .post<ICryptData, any>("Service/UpdateSecureValue", ec)
              .then((g) => {
                //
              })
              .catch((e) => {
                globalInfoService.sendMessage(<IInfo>{
                  msg: "Update des Wertes geht leider nicht ...",
                  error: e,
                });
              });
          })
          .catch((ex) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Unmöglich sichere Daten zu speichern",
              error: ex,
            });
          });
      } else if (update.crud.toLowerCase() == CrudType.updatestrvalue) {
        if (update.id < 1) return;
        dataService.InitbyToken(store.getters.user.token);
        dataService
          .get<any>(
            "Service/UpdateStrValue?valueid=" +
              update.id +
              "&strvalue=" +
              update.strvalue
          )
          .then((t) => {            
            let idx = this.fields.findIndex((g) => g.id == update.id);            
            if (idx > -1) {
              Vue.set(this.fields, idx, update);              
            }
          })
          .catch((e) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Update des Wertes geht leider nicht ...",
              error: e,
            });
          });
      } else {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Unbekannte Aktion " + update.crud,
          type: InfoType.warning,
        });
      }
    });
  }

  /**Impport dynamisches Laden */
  importBaseComponent(name: string) {
    try {
      return () => import("./Base/" + name + ".vue");
    } catch (e) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Fehler Laden Komponente",
        error: e,
      });
    }
  }

  loadbyKategorie(kat: string): void {
    {
      var url = "Service/GetDetailValuesbyKategorie/" + this.baseid + "/" + kat;
      dataService.get<IStrDetailValue[]>(url).then(
        (g) => {
          if (g.length > 0) {
            this.title =
              g[0].typname +
              (g[0].parent != undefined &&
              g[0].parent.base_identifier != undefined &&
              g[0].parent.base_identifier.length > 0
                ? " (" + g[0].parent.base_identifier + ") "
                : "");
          }
          this.fields = g;
          if (g.length > 0) {
            this.basemodel = <IStrDetailValue>g[0];
          }
          var hasmedias = false;
          this.fields.forEach((f) => {
            f.crud = this.modus;
            f.component = this.getComponentType(f);
            if ( f.component != undefined &&
              f.component.startsWith("Picture") ||
              f.component.startsWith("Movie") || f.component.startsWith("Audio")
            ) {
              hasmedias = true;
            }
          });

          if (hasmedias) {
            this.loadMedias();
          }
        },
        (e) => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Details holen",
            error: e,
          });
        }
      );
    }
  }

  //Focus und scroll
  setfocus(thismodel: IStrDetailValue): void {
    try {
      if (!thismodel) return;
      var fd = false;
      this.childRefs.forEach((element: any) => {
        var c = element["ref" + thismodel.id.toString()] as any;
        if (c != undefined && c.$el) {
          if (!fd) {
            this.$nextTick(() => {
              if (thismodel.strvalue && thismodel.strvalue.length > 0) {
                c.$el.scrollIntoView(false);
              } else {
                c.focus();
              }
            });
            fd = true;
          }
        }
      });
    } catch (e) {
      console.debug(e);
    }
  }
  load(fld: string): void {
    this.fieldname = "";
    dataService.InitbyToken(store.getters.user.token);

    if (this.modus.toLowerCase() == "create") {
      var urlfieldnames = "Service/GetNewValues/" + this.typ;
      dataService
        .get<IStrDetailValue[]>(urlfieldnames)
        .then(
          (g) => {
            this.title =
              this.typ +
              (g[0].parent != undefined &&
              g[0].parent.base_identifier != undefined &&
              g[0].parent.base_identifier.length > 0
                ? " (" + g[0].parent.base_identifier + ") "
                : "");
            this.fields = g;
            if (g.length > 0) {
              this.basemodel = <IStrDetailValue>g[0];
            }
            this.fields.forEach((f) => {
              f.crud = CrudType.create;
              f.component = this.getComponentType(f);
            });
            this.felder = new Array<string>();
          },
          (e) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Neue Daten laden",
              error: e,
            });
          }
        )
        .then(() => {
          this.setfocus(this.fields[0]);
        });
    } else {
      var url =
        "Service/GetDetailValues/" +
        this.baseid +
        "/" +
        store.getters.user.token;
      dataService
        .get<IStrDetailValue[]>(url)
        .then(
          (g) => {
            if (g.length > 0) {
              this.title =
                g[0].typname +
                (g[0].parent != undefined &&
                g[0].parent.base_identifier != undefined &&
                g[0].parent.base_identifier.length > 0
                  ? " (" + g[0].parent.base_identifier + ") "
                  : "");
            }
            this.fields = g;

            if (g.length > 0) {
              this.basemodel = <IStrDetailValue>g[0];
            }
            var hasmedias = false;
            this.fields.forEach((f) => {
              f.crud = this.modus;
              f.component = this.getComponentType(f);
              if ( f.component != undefined &&
                f.component.startsWith("Picture") ||
                f.component.startsWith("Movie") ||
                f.component.startsWith("Audio")
              ) {
                hasmedias = true;
              }
            });

            if (hasmedias) {
              this.loadMedias();
            }

            var typ = g.length > 0 ? g[0].typname : "unbekannt";
            this.typ = typ;
            var urlkat = "Service/GetKategorien/" + typ;
            dataService.get<string[]>(urlkat).then(
              (g) => {
                this.kategorien = g;
              },
              (e) => {
                globalInfoService.sendMessage(<IInfo>{
                  msg: "Kategorien laden",
                  error: e,
                });
              }
            );
          },
          (e) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Details holen",
              error: e,
            });
          }
        )
        .then(() => {
          dataService.InitbyToken(store.getters.user.token);
          var urlfieldnames = "Service/GetUnusedFieldNames/" + this.baseid;
          dataService.get<string[]>(urlfieldnames).then(
            (g) => {
              this.felder = g;
              try {
                var all = this.fields
                  .filter((t) => t.fieldname === fld)
                  .sort((t) => t.id);
                var focusfld =
                  fld && all.length > 0 ? all[all.length - 1] : this.fields[0];
                this.setfocus(focusfld);
              } catch (e) {}
            },
            (e) => {
              globalInfoService.sendMessage(<IInfo>{
                msg: "Felder laden",
                error: e,
              });
            }
          );
        });
    }
  }


  loadMedias(): void {
    dataService.InitbyToken(store.getters.user.token);
    var url = "Service/GetMedias/" + this.baseid;
    dataService.get<IMedia[]>(url).then(
      (g) => {
        this.medias = g;
      },
      (e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Bilder und Videos laden ging nicht...",
          error: e,
        });
      }
    );
  }

  data() {
    return {
      fields: [{}],
      cansave: true,
      fieldnames: this.felder,
      basemodel: {},
      kategorien: [],
      kategorie: "",
      childRefs: [],
      modus: "noedit",
      
    };
  }
}
