export interface KursTypObject
{
    KursType: KursType;
    Typ: string;
    Id: number;
}

export enum KursType
{
    Kurse = 0,
    Guv = 2,
    Abgeschlossen = 3
}


export enum SearchType {
  unbekannt=0,
  NachTypname=1,
  InnerLink=2,
  Router=3,
  LastSearch=4,
  OuterLink =5,
  FullText = 6,
}



export enum EntwicklungsType {
  fällt = -1,
  neutral = 1,
  steigt = 2,
}

export enum CrudType {
  update='update',
  create='create',
  noedit='noedit',
  updatestrvalue='updatestrvalue',
  updatememovalue='updatememovalue',
  deletevalue='deletevalue',
  deletebase='deletebase',
  // VErknüpfung
  updatechildbaseid='updatechildbaseid',
  deletechildbaseid="deletechildbaseid"
}




export enum InfoType {
  error=0,
  debug=1,
  info=2,
  loading=3,
  finished=4,
  warning=5,
  success=6,
  Login
}
